import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import * as Yup from 'yup';

// components
import SupportForm from '../SupportForm';
import ReportInfo from '../sections/ReportInfo';
import AddressInfo from '../sections/AddressInfo';
import PackageInfo from '../sections/PackageInfo';

const ChangeAddress = () => {
  const [branchDelivery, setBranchDelivery] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(false);
  const [values, setValues] = useState({
    shippingId: null,
    incidentType: 'Cambio de Domicilio',
    serviceName: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    cp: '',
    city: '',
    state: '',
    references: '',
    phoneTo: '',
    // user typing the next fields
    email: '',
    betweenStreet: '',
    facade: '',
    // info package
    packageContent: '',
    packagingDescription: '',
    invoiceFile: '',
    comment: '',
    // new field
    deliveryType: '',
  });
  const [errors, setErrors] = useState({
    shippingId: '',
    incidentType: '',
    email: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    cp: '',
    betweenStreet: '',
    facade: '',
    references: '',
    phoneTo: '',
    packageContent: '',
    packagingDescription: '',
    invoiceFile: '',
    deliveryType: '',
  });
  // Schema Validation
  const validationSchema = Yup.object().shape({
    shippingId: Yup.string().required('Por favor, introduce el ID de envío').nullable(),
    email: Yup.string().email('Por favor, introduce un correo electrónico válido').required('El correo electrónico es obligatorio'),
    street: Yup.string().required('No olvides ingresar el nombre de la calle'),
    streetNumber: Yup.string().required('Ingresa el número de la calle'),
    city: Yup.string().required('Selecciona una ciudad'),
    state: Yup.string().required('Selecciona un estado'),
    cp: Yup.string().min(5, 'El código postal debe tener 5 números.').required('Introduce el código postal'),
    neighborhood: Yup.string().required('Introduce la colonia'),
    betweenStreet: Yup.string().required('Indica las calles entre las cuales se ubica'),
    references: Yup.string().required('Proporciona una referencia'),
    phoneTo: Yup.string().min(10, 'El número de teléfono debe tener 10 dígitos').required('El número de teléfono es obligatorio'),
    facade: Yup.string().required('Proporciona la descripción de la fachada'),
    packagingDescription: Yup.string().required('Describe el tipo de embalaje'),
    // invoiceFile: Yup.string().required('Sube la factura'),
    packageContent: Yup.string().required('Describe el contenido del paquete'),
    deliveryType: Yup.string().required('Campo requerido'),
  });
  // ('Entregado en otra dirección', 'Entregado en otra dirección'),
  // ('Trasladado a una sucursal', 'Trasladado a una sucursal'),
  // ('Regresado al domicilio de origen', 'Regresado al domicilio de origen'),
  const infoSection = `Para solicitar el cambio de envío a sucursal, por favor completa los datos de la dirección donde deseas que se deje el paquete.
  Para el cambio de domicilio en guías de DHL, por favor agrega la nueva dirección.
  FedEx y Estafeta no ofrecen la opción de cambio de domicilio.`;
  // console.log('Cambio de Domicilio data:', values);
  return (
    <SupportForm values={values} setValues={setValues} setErrors={setErrors} validationSchema={validationSchema} formTitle="Cambio de Domicilio">
      <ReportInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} />
      <Col xs="12" md="6" className="position-relative">
        <Form.Label>Requiero que mi paquete sea:</Form.Label>
        <span className="text-primary ms-1">*</span>
        {/* <div style={{ position: 'relative', zIndex: 1 }}> </div> */}
        <div className="ms-2">
          <input
            type="checkbox"
            className="form-check-input me-2"
            checked={deliveryAddress}
            onClick={() => {
              setDeliveryAddress(!deliveryAddress);
              setBranchDelivery(false);
              setValues((prev) => {
                return { ...prev, deliveryType: 'Entregado en otra dirección' };
              });
              setErrors((prev) => {
                return { ...prev, deliveryType: '' };
              });
            }}
          />
          <label className="form-label">Entrega en otra dirección</label>
        </div>
        <div className="ms-2">
          <input
            type="checkbox"
            className="form-check-input me-2"
            checked={branchDelivery}
            onClick={() => {
              setBranchDelivery(!branchDelivery);
              setDeliveryAddress(false);
              setValues((prev) => {
                return { ...prev, deliveryType: 'Trasladado a una sucursal' };
              });
              setErrors((prev) => {
                return { ...prev, deliveryType: '' };
              });
            }}
          />
          <label className="form-label">Trasladar a una sucursal</label>
        </div>
        {errors.deliveryType && <div className={`invalid-tooltip ${errors.deliveryType ? 'd-block' : 'd-none'}`}>{errors.deliveryType}</div>}
      </Col>
      <AddressInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} info={infoSection} />
      <PackageInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} />
    </SupportForm>
  );
};

export default ChangeAddress;
