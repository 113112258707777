import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// External libraries
import moment from 'moment';

// External libraries
const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const OverweightCard = ({ data }) => {
  // console.log(data);
  return (
    <Card className="mb-2 px-0">
      <Row className="g-0 h-100 sh-lg-9 position-relative ">
        <Col xs="auto" className="positio-relative">
          <img src={data.service_img} alt="product" className="card-img card-img-horizontal sw-9 sw-sm-11 h-100" />
        </Col>
        <Col className="py-4 py-lg-0 ps-4 pe-4 ps-sm-5 h-100 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" sm="10" lg="4" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center">
              <div className="text-muted text-small d-lg-none mb-1">Servicio / ID Envío</div>
              <div className="text-primary">
                {data.shipping_code}
                <div className="text-medium text-muted text-truncate">{data.shipping_id}</div>
                {/* <div className="text-small text-muted text-truncate">{data.id_guide}</div> */}
              </div>
            </Col>
            <Col lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-center order-3">
              <div className="text-muted text-small d-lg-none">Fecha cargo</div>
              <div className="lh-1 text-alternate">{`${moment(data.creation_date).format('DD-MM-YYYY')}`}</div>
            </Col>
            <Col sm="8" className="order-4 bg-success d-none d-sm-flex d-lg-none invisible">
              -
            </Col>
            <Col xs="8" sm="2" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center  align-items-center order-4">
              <div className="text-muted text-small d-lg-none">Kg cobrados</div>
              <div className="lh-1 text-alternate ">{data.declared_kg} </div>
            </Col>
            <Col xs="4" sm="2" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start align-items-center justify-content-center order-5">
              <div className="text-muted text-small d-lg-none">Kg reales</div>
              <div className="lh-1 text-alternate">{data.real_kg}</div>
            </Col>
            <Col
              xs="12"
              sm="2"
              lg="1"
              className="d-flex flex-column mb-2 mb-lg-0 align-items-start align-items-end align-items-sm-center order-2 order-lg-last justify-content-lg-center"
            >
              <div className="text-muted text-small d-lg-none">Cargo</div>
              <span className="text-alternative text-primary">
                <span className="text-small text-muted">$</span>
                {/* {fmDecimal.from(parseInt(data.charge, 10), { symbol: '$' })} */}
                {fmDecimal.from(data.charge)}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default OverweightCard;
