import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Dropdown, Accordion } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// import { Accordion, AccordionSection } from 'react-rainbow-components';
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
import AdminLoader from '../../../components/loading/AdminLoader';
import { initialsUpperCase } from '../../../utils/textFormat';
import { getRateList } from '../../api/Api-admin';

const Tariff = () => {
  const title = 'Tarifarios';
  const description = 'Tarifario Plataforma Autopaquete';
  const [isInitialLoader, setIsInitialLoader] = useState(true);
  const [ratesList, setRatesList] = useState([]);
  const [rateGroup, setRateGroup] = useState([]);

  const [tariff, setTariff] = useState('AVANZADO');

  const getRates = (tariffName) => {
    setTariff(tariffName);
    const result = ratesList.find((rate) => rate.name === tariffName);
    // console.log(ratesList.find((rate) => rate.name === tariffName));
    setRateGroup(result.group_rates);
  };
  // console.log(`Grupo de tarifas de ${tariff}`, rateGroup);
  // console.log("TARIFARIOS ", ratesList);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await getRateList();
        // console.log('obtener tarifario:', response);
        if (response.rates_list) {
          setRatesList(response.rates_list);
          setRateGroup(response.rates_list[0].group_rates);
        } else if (response.error) {
          handleAPIError(response);
        }
      } catch (err) {
        // console.log(err);
        handleCatchError(err);
      } finally {
        setIsInitialLoader(false);
      }
    };

    fetchRates();
  }, []);

  return (
    <>
      {isInitialLoader ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          {/* Title Start */}
          <div className="page-title-container">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back invisible" to="/">
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Inicio</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </div>
          {/* Title End   style={{backgroundColor:"salmon", height:"500px"}} */}
          <Col lg="10">
            <Row className="my-5 py-5 d-flex justify-content-end">
              <Col xs="auto">
                <Dropdown className="d-inline-block">
                  <Dropdown.Toggle variant="foreground-alternate" className="text-start shadow sw-auto">
                    {tariff}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow sw-30">
                    {ratesList?.map((tariffName) => (
                      <Dropdown.Item key={tariffName.id} className={`${tariffName === tariffName.name && 'd-none'}`} onClick={() => getRates(tariffName.name)}>
                        {tariffName.name}{' '}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs="auto">
                <Dropdown align={{ xs: 'end' }} className="d-inline-block">
                  <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-30">
                    SERVICIO
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow dropdown-menu-end">
                    <Dropdown.Item>20 Envíos</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            {/* <h2 className="small-title invisible">Tarifario</h2>
            <Card className="mb-5">
              <Card.Body>
              </Card.Body>
            </Card> */}
            <h2 className="small-title">{`Grupo Tarifas ${initialsUpperCase(tariff)}`}</h2>

            <Card className="mb-5">
              <Card.Body>
                <Col xs="12" xxl="12">
                  <Accordion defaultActiveKey="0" flush>
                    {rateGroup.map((rate) => (
                      <Accordion.Item eventKey={rate.id} key={rate.id} className="accordion">
                        <Accordion.Header>
                          {/* style={{fontSize:"18px"}}  */}
                          <h3 className="text-semi-large">{initialsUpperCase(rate.service)}</h3>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            {' '}
                            Sobrepeso
                            <span className="text-small text-muted ps-1">$</span>
                            {rate.overweight}
                          </div>

                          <ul>
                            {rate.rates.map((element, index) => (
                              <li key={index}>
                                {`De ${element.initial_limit} kg a ${element.final_limit} kg `}
                                <span className="text-small text-muted">$</span>
                                <span>{element.prices}</span>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </>
      )}
    </>
  );
};

export default Tariff;
