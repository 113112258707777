import React from 'react';
import { CSVLink } from 'react-csv';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// external libraries
import moment from 'moment';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const OverweightCSV = ({ overweights, crmId }) => {
  // console.log('CSV DATA OVERWEIGHT:', overweights);
  const csvData = overweights.map((overweight) => ({
    date: moment(overweight.creation_date).format('DD-MM-YY'),
    service: overweight.shipping_code,
    labelId: overweight.shipping_id,
    declaredKg: overweight.declared_kg,
    realKg: fmDecimal.from(overweight.real_kg),
    charge: fmDecimal.from(overweight.charge, { symbol: '$' }),
  }));

  const headers = [
    { label: 'Fecha', key: 'date' },
    { label: 'Servicio', key: 'service' },
    { label: 'ID Envio', key: 'labelId' },
    { label: 'Kilos Declarados', key: 'declaredKg' },
    { label: 'Kilos Reales', key: 'realKg' },
    { label: 'Cargo', key: 'charge' },
  ];
  return (
    <CSVLink data={csvData} headers={headers} className="btn btn-primary" filename={`Sobrepesos ${crmId}.csv`} target="_blank" style={{ height: '36px' }}>
      <span>
        <CsLineIcons icon="download" size="15" className="mb-1" /> Excel{' '}
      </span>
    </CSVLink>
  );
};
export default OverweightCSV;
