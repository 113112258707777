import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { initialsUpperCase, startUpperCase } from 'utils/textFormat';

const ShippingInfoDetail = ({ shippingData }) => {
  // - Global State from Store.js form Values
  const { ratesData } = useSelector((state) => state.shipping_rates);
  const { senderData } = useSelector((state) => state.shipping_sender);
  const { receiverData } = useSelector((state) => state.shipping_receiver);
  const { parcelData } = useSelector((state) => state.shipping_parcel);

  const fullAddressFrom = `${shippingData.addressFrom?.street} ${shippingData.addressFrom?.street_number}, ${shippingData.addressFrom?.neighborhood}, ${shippingData.addressFrom?.zip_code}, ${senderData.fields?.city}, ${senderData.fields.state?.name}.`;
  const fullAddressTo = `${shippingData.addressTo?.street} ${shippingData.addressTo?.street_number}, ${shippingData.addressTo?.neighborhood}, ${shippingData.addressTo?.zip_code}, ${receiverData.fields?.city}, ${receiverData.fields.state?.name}.`;
  return (
    <Card className="mb-5 w-100 sw-lg-35">
      <Card.Body>
        <div className="mb-5">
          <p className="text-small text-muted mb-2">DIRECCIÓN DE ORIGEN</p>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="user" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{initialsUpperCase(shippingData.addressFrom?.name)}</Col>
          </Row>
          {shippingData.addressFrom?.company && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="building" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{startUpperCase(shippingData.addressFrom?.company)}</Col>
            </Row>
          )}

          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="pin" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{initialsUpperCase(fullAddressFrom)}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="destination" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{startUpperCase(shippingData.addressFrom?.reference)}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="phone" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{shippingData.addressFrom?.phone}</Col>
          </Row>
          {/* <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="email" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">desarrollo.web@autopaquete.com</Col>
            </Row> */}
        </div>

        <div className="mb-5">
          <p className="text-small text-muted mb-2">DIRECCIÓN DE DESTINO</p>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="user" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{initialsUpperCase(shippingData.addressTo?.name)}</Col>
          </Row>
          {shippingData.addressTo?.company && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="building" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{startUpperCase(shippingData.addressTo?.company)}</Col>
            </Row>
          )}

          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="pin" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{initialsUpperCase(fullAddressTo)}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="destination" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{startUpperCase(shippingData.addressTo?.reference)}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="phone" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{shippingData.addressTo?.phone}</Col>
          </Row>
          {/* <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="email" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">desarrollo.web@autopaquete.com</Col>
            </Row> */}
        </div>

        <div className="mb-5">
          <p className="text-small text-muted mb-2">PAQUETE</p>
          {parcelData.fields?.name && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="sign" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{startUpperCase(parcelData.fields?.name)}</Col>
            </Row>
          )}

          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="edit-square" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">
              {startUpperCase(shippingData.parcel?.product_description)}
              {shippingData.parcel?.product_value > 0 && (
                <OverlayTrigger delay={{ show: 500, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Valor del contenido</Tooltip>}>
                  <span>{` - $${shippingData.parcel?.product_value}`}</span>
                </OverlayTrigger>
              )}
            </Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="box" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{`${shippingData.parcel?.depth} x ${shippingData.parcel?.width} x ${shippingData.parcel?.height} - ${shippingData.parcel?.weight} kg`}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="abacus" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{`${ratesData.parcel?.volumetric_weight} kg Volumétrico `}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="dollar" size="18" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{`${ratesData.parcel?.total_weight} kg Total`}</Col>
          </Row>
          {ratesData.parcel?.multi_pieces > 1 && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="print" size="18" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{`Multi-piezas ${ratesData.parcel?.multi_pieces} `}</Col>
            </Row>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
export default ShippingInfoDetail;
