import React from 'react';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const InputTextFetchData = ({
  label = '',
  icon = '',
  maxLength = '',
  placeholder = '',
  isRequired = '',
  helpText = '',
  isLoading = '',
  isError = '',
  error = '',
  value = '',
  setValues = '',
  setField = '', // update its value
  cleanError = '',
  validation = '',
  fieldName = '',
}) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired === 'true' && <span className="text-primary ms-1">*</span>}

      <InputGroup>
        <InputGroup.Text>
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
              style={{
                width: '10px',
                height: '10px',
                borderWidth: '1px',
                color: '#850000',
              }}
            />
          ) : (
            <CsLineIcons icon={icon} size="16" className="text-alternate" />
          )}
        </InputGroup.Text>
        <Form.Control
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValues(e); // función que realiza la petición de datos
            setField(e.target.value, validation, fieldName); // funcion que actualiza su valor
            // Validate that cleanError exists before calling it
            if (cleanError) {
              cleanError('', fieldName);
            }
          }}
        />
      </InputGroup>
      <span className="text-medium d-block">{helpText}</span>
      <div className={`text-medium text-primary  bg-successe ${isError ? 'visible' : 'invisible'}`}>{`¡Error: ${error} !`}</div>
      {/* <span className={`text-medium text-primary ${isError ? 'visible' : 'invisible'}`}>
        ¡Error: El ID de envío parece ser incorrecto. Asegúrate de ingresarlo correctamente!{' '}
      </span> */}
    </>
  );
};

export default InputTextFetchData;
