import React, { useState, useEffect, useRef } from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Dropdown } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// utils
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
// components
import AdminLoader from '../../../../../../components/loading/AdminLoader';
import LoadingPulse from '../../../../../../components/loading/LoadingPulse';
import Paginate from '../../../../../components/Paginate';
import OverweightCSV from '../../../../../../components/reactCsv/OverweightCSV';
import OverweightRow from './components/OverweightRow';
import OverweightCard from './components/OverweightCard';
// api
import { getOverweights } from '../../../../../api/Api-admin';

const OverweightHistory = () => {
  const title = 'Historial Sobrepesos';
  const description = 'Historial Sobrepesos Plataforma Autopaquete';
  const { crmUserId, userName } = useParams();
  const history = useHistory();

  // - Local States
  const [overweightHistory, setOverweightHistory] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [changePage, setChangePage] = useState(1);
  const [changePageSize, setChangePageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // - Use references to do scroll Up
  const startRef = useRef(null);
  const settingPage = (record) => {
    setChangePage(1);
    setChangePageSize(record);
  };
  // - Get all statement
  const handlerGetOverweightsHistory = async () => {
    try {
      setIsLoading(true);
      const response = await getOverweights(crmUserId, changePage, changePageSize);
      if (response.overweights !== undefined) {
        // console.log('Get overweights response', response);
        setOverweightHistory(response.overweights);
        setCurrentPage(response.current_page);
        setPageSize(response.page_size);
        setTotalPages(response.total_pages);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        handleAPIError(response);
        // const firstKey = Object.keys(response.error)[0];
        // ToastErrorApi(response.error[firstKey]);
      }
    } catch (error) {
      handleCatchError(error);
      setOverweightHistory(overweightHistory);
    } finally {
      setIsInitialLoading(false);
      setIsLoading(false);
    }
  };

  // - Whenever the pagination status change
  useEffect(() => {
    handlerGetOverweightsHistory();
  }, [changePage, changePageSize]);

  // console.log('history ----:', overweightHistory);
  return (
    <>
      {isInitialLoading ? (
        <AdminLoader />
      ) : (
        <>
          {isLoading && <LoadingPulse />}
          {/* pageContainer I use it to add opacity screen while loading data */}
          <div id="pageContainer">
            <HtmlHead title={title} description={description} />
            {/* *** Title Start ***  */}
            <div className="page-title-container" ref={startRef}>
              <Row className="g-0">
                <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
                  <div className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer" onClick={() => history.goBack()}>
                    <CsLineIcons icon="chevron-left" size="13" />
                    <span className="align-middle text-small ms-1">Atrás</span>
                  </div>
                  <h1 className="mb-0 pb-0 display-4" id="title">
                    {title}
                  </h1>
                </Col>
              </Row>
            </div>
            {/* *** Title End *** */}
            {/* *** Dropdown and Excel Buttons Start ***  */}
            <h2 className="cta-1 text-primary mb-5 text-center text-sm-start">{userName}</h2>
            <Row className="g-0 mb-3">
              <Row className="g-0 d-flex justify-content-end aling-items-center">
                <Col xs="auto">{overweightHistory?.length >= 10 && <OverweightCSV overweights={overweightHistory} crmId={crmUserId} />}</Col>
                <Col xs="auto" className=" p-0 ms-2">
                  <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${overweightHistory?.length < 10 && 'invisible'}`}>
                    <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                      {`${pageSize} Sobrepesos`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow dropdown-menu-end">
                      <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                        10 Sobrepesos
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                        20 Sobrepesos
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                        50 Sobrepesos
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                        100 Sobrepesos
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* Length End */}
                </Col>
              </Row>
              <Row>
                <div className={`text-muted mt-3 ${overweightHistory?.length < 2 && 'invisible'}`}>{`Página ${currentPage}`}</div>
              </Row>
            </Row>
            {/* *** Dropdown and Excel Buttons End ***  */}
            {/* *** Main Page Start *** */}
            <div style={{ minHeight: '50vh' }} id="cardContainer">
              {/* List Header Start */}
              <OverweightRow />
              {/* List Header End */}
              {/* List shipment Items End */}
              {overweightHistory?.length === 0 ? (
                <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
                  {/* <h3 className="text-center mt-5">Error</h3> */}
                  <img alt="box" src="/img/animation/emptyBox.webp" style={{ width: '50px', height: '50px' }} />
                </div>
              ) : (
                <>
                  {/* List shipment Items Start */}
                  {overweightHistory?.map((overweight, index) => (
                    <OverweightCard key={index} data={overweight} />
                  ))}
                  {/* List shipment Items End */}
                  {/* Pagination Start */}
                  <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                    <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
                  </div>
                  {/* Pagination End */}
                </>
              )}
            </div>
            {/* *** Main Page End *** */}
          </div>
        </>
      )}
    </>
  );
};

export default OverweightHistory;
