import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import 'react-toastify/dist/ReactToastify.css';
// import {initialsUpperCase, startUpperCase} from 'utils/textFormat';

// import Loading from '../../../components/loading/Loading';
import LoadingCar from '../../../components/loading/LoadingCar';

// components
import SupplierCard from './components/SupplierCard';
import ShippingInfoDetail from './components/ShippingInfoCard';

const Services = () => {
  const title = 'Servicios';
  const description = 'Servicios Plataforma Autopaquete';
  const history = useHistory();

  // - Global State from Store.js form Values
  const { ratesData } = useSelector((state) => state.shipping_rates);
  const { senderData } = useSelector((state) => state.shipping_sender);
  const { receiverData } = useSelector((state) => state.shipping_receiver);
  const { parcelData } = useSelector((state) => state.shipping_parcel);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shippingData = {
    addressFrom: {
      company: senderData.fields.company,
      name: senderData.fields.name,
      phone: senderData.fields.phone,
      // email: '',
      street: senderData.fields.street,
      street_number: senderData.fields.streetNumber,
      neighborhood: senderData.fields.neighborhood,
      zip_code: senderData.fields.zip,
      reference: senderData.fields.placeRef,
    },
    addressTo: {
      company: receiverData.fields.company,
      name: receiverData.fields.name,
      phone: receiverData.fields.phone,
      // email: '',
      street: receiverData.fields.street,
      street_number: receiverData.fields.streetNumber,
      neighborhood: receiverData.fields.neighborhood,
      zip_code: receiverData.fields.zip,
      reference: receiverData.fields.placeRef,
    },
    parcel: {
      product_description: parcelData.fields.product_description,
      product_value: parseInt(parcelData.fields.product_value, 10),
      insurance: parcelData.fields.insurance,
      height: parcelData.fields.height,
      width: parcelData.fields.width,
      depth: parcelData.fields.depth,
      weight: parcelData.fields.weight,
      multi_pieces: parcelData.fields.multi_pieces,
    },
  };
  useEffect(() => {
    if (shippingData.addressFrom.name === '') {
      history.push('/envios/enviar');
    }
  }, [history, shippingData]);
  // console.log('create shipping data:', shippingData);
  // console.log('valor y tipo de dato de product_value:', shippingData.parcel.product_value, typeof shippingData.parcel.product_value);
  // console.log('data rates in services:', ratesData);
  return (
    <>
      {isLoading && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        {/* Title Start */}
        <div className="page-title-container">
          <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/envios/enviar">
            <CsLineIcons icon="chevron-left" size="13" />
            <span className="align-middle text-small ms-1">Atrás</span>
          </NavLink>
          <h1 className="mb-0 pb-0 display-4" id="title">
            {title}
          </h1>
        </div>
        {/* Title End */}
        <Row>
          <Col xs="12" className="col-lg order-1 order-lg-1">
            {/* Items Start */}
            <h2 className="small-title">Lista disponible</h2>
            <div className="mb-5">
              {ratesData.rates?.map((rate) => (
                <SupplierCard key={rate?.shipping_code} rate={rate} shippingData={shippingData} setIsLoading={setIsLoading} page="shipping" />
              ))}
            </div>
            {/* Items End */}
          </Col>
          <Col xs="12" lg="auto" className="order-0 order-lg-0">
            <h2 className="small-title">Detalles del envío</h2>
            <ShippingInfoDetail shippingData={shippingData} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Services;
