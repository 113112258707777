import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// utils
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
// api
import { getOverweightOrderById } from '../../api/Api-admin';

// components
import AdminLoader from '../../../components/loading/AdminLoader';

// External libraries
const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const OrderDetail = () => {
  const title = 'Detalle orden';
  const description = 'Detalle orden Plataforma Autopaquete';
  // Local State
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isInitialLoader, setIsInitialLoader] = useState(true);
  const history = useHistory();
  useEffect(() => {
    getOverweightOrderById(orderId)
      .then((response) => {
        // console.log('response order detail:', response);
        if (response.results !== undefined) {
          setOrder(response);
          setIsInitialLoader(false);
        } else if (response.error) {
          history.goBack();
          handleAPIError(response);
        }
      })
      .catch((error) => {
        history.goBack();
        // handleCatchError(error);
        // console.log('error error:', err);
      });
  }, []);

  return (
    <>
      {isInitialLoader ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          <div className="page-title-container mb-5">
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-3 mb-sm-0 me-auto">
                <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/overweight">
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Atrás</span>
                </NavLink>
                <h1 className="mb-0 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </div>
          {/* Counters Start */}
          <Row className="g-2 mb-3 mt-5">
            <Col sm="6" lg="3">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="clipboard" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">ID Orden</div>
                      <div className="text-primary">{order?.order_id}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" lg="3">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="calendar" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">Fecha Creación</div>
                      <div className="text-primary">{moment(order?.created).utcOffset('-06:00').format('DD-MM-YYYY HH:mm:ss')}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" lg="3">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="shipping" className="text-primary" />
                        {/* clipboard */}
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">Sobrepesos solicitados</div>
                      <div className="text-primary">{order?.requested_overweights}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <h2 className="small-title">Orden Estatus</h2>
          <Row className="g-2 mb-5">
            <Col sm="6" lg="3">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="check" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">Exitosas</div>
                      <div className="text-primary">{order?.completed_overweights}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" lg="3">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="multiply" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">Fallidas</div>
                      <div className="text-primary">{order?.failed_overweights}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" lg="3">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="spinner" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">Pendientes</div>
                      <div className="text-primary">{order?.pending_overweights}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col sm="6" lg="3">
              <Card className="sh-13 sh-lg-15 sh-xl-14">
                <Card.Body className="h-100 py-3 d-flex align-items-center">
                  <Row className="g-0 align-items-center">
                    <Col xs="auto" className="pe-3">
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="dollar" className="text-primary" />
                      </div>
                    </Col>
                    <Col>
                      <div className="d-flex align-items-center lh-1-25">Total Solicitudes</div>
                      <div className="text-primary">{order?.requested_overweights}</div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
          {/* Counters End */}
          {/* List Header Start */}
          <h2 className="small-title">Sobrepesos</h2>
          <Row className="g-0 mb-2 d-none d-lg-flex">
            <Col xs="auto" className="sw-11 d-none d-lg-flex" />
            <Col>
              <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-4 h-100">
                <Col xs="3" lg="4" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-small cursor-pointer">SERVICIO / ID SOBREPESO</div>
                </Col>
                <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">FECHA CARGO</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
                  <div className="text-muted text-small cursor-pointer">KG COBRADOS</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
                  <div className="text-muted text-small cursor-pointer">KG REALES</div>
                </Col>
                <Col
                  xs="2"
                  lg="1"
                  className="d-flex flex-column pe-1 justify-content mb-2 mb-lg-0 align-items-center order-2 order-lg-last justify-content-lg-center"
                >
                  <div className="text-muted text-small cursor-pointer">CARGO</div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* List Header End */}
          {/* List overweight Items Start */}
          {order?.results !== null &&
            order?.results.map((overweight) =>
              !overweight.is_charged ? (
                <Card className="mb-2" key={overweight?.index}>
                  <Row className="g-0 h-100 sh-lg-9 position-relative ">
                    <Col xs="auto" className="position-relative d-flex justify-content-center align-items-center" style={{ width: '88px' }}>
                      <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                        <CsLineIcons icon="multiply" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="py-4 py-lg-0 ps-5 pe-4 h-100">
                      <Row className="g-0 h-100 align-content-center">
                        <Col xs="3" lg="4" className="d-flex flex-column mb-lg-0 mb-3 pe-3 order-1 h-lg-100 justify-content-center ">
                          <OverlayTrigger delay={{ show: 0, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">{overweight.info_error}</Tooltip>}>
                            <p className="text-semi-large text-primary m-0 cursor-pointer w-25">error</p>
                          </OverlayTrigger>
                          <div className="text-small text-truncate">error</div>
                        </Col>
                        <Col xs="2" lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                          <div className="lh-1 text-alternate">error</div>
                        </Col>
                        <Col xs="2" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-center order-4">
                          <div className="lh-1 text-alternate">0</div>
                        </Col>
                        <Col
                          xs="2"
                          lg="2"
                          className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start  align-items-lg-center justify-content-center order-5"
                        >
                          <div className="lh-1 text-alternate">0</div>
                        </Col>
                        <Col
                          xs="2"
                          lg="1"
                          className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start align-items-lg-center justify-content-center order-5"
                        >
                          <div className="text-muted text-small d-lg-none mb-1">Cargo</div>
                          <div className="lh-1 text-alternate">
                            <span className="text-small text-muted">$</span>0
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              ) : (
                <Card className="mb-2" key={overweight?.index}>
                  <Row className="g-0 h-100 sh-lg-9 position-relative">
                    <Col xs="auto" className="positio-relative">
                      {/* <NavLink to="/finanzas/sobrepesos">
                        <img src={overweight.service_img} alt="product" className="card-img card-img-horizontal sw-9 sw-sm-11 h-100" />
                      </NavLink> */}
                    </Col>
                    <Col className="py-4 py-lg-0 ps-4 pe-4 ps-sm-5 h-100">
                      <Row className="g-0 h-100 align-content-center">
                        <Col xs="12" sm="10" lg="4" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center">
                          {/* <div className="text-muted text-small d-lg-none mb-1">Servicio / ID Envío</div> */}
                          {/* onClick={() => dispatch(setShippingDetail(overweight.id_guide))} */}
                          {/* <NavLink to="/finanzas/sobrepesos" > </NavLink> */}
                          {/* <div className="text-primary">
                            {overweight.service_name}
                            <div className="text-small text-muted text-truncate">{`ID: ${overweight.track_number}`}</div>
                          </div> */}
                        </Col>
                        <Col lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                          <div className="text-muted text-small d-lg-none">kg_to_collect</div>
                          <div className="lh-1 text-alternate">{overweight.kg_to_collect}</div>
                          {/* <div className="lh-1 text-alternate">{moment(overweight.date).utcOffset('-06:00').format('DD-MM-YY HH:mm:ss')}</div> */}
                        </Col>
                        <Col sm="8" className="order-4 bg-success d-none d-sm-flex d-lg-none invisible">
                          -
                        </Col>
                        <Col xs="8" sm="2" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center  align-items-center order-4">
                          <div className="text-muted text-small d-lg-none">kg_coverage_for_shipping</div>
                          {/* <span className="text-small text-muted d-none d-lg-inline">kg</span> */}
                          <div className="lh-1 text-alternate ">{overweight.kg_coverage_for_shipping} </div>
                        </Col>
                        <Col
                          xs="4"
                          sm="2"
                          lg="2"
                          className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start align-items-center justify-content-center order-5"
                        >
                          <div className="text-muted text-small d-lg-none">Kg reales</div>
                          <div className="lh-1 text-alternate">{overweight.real_weight}</div>
                        </Col>
                        <Col
                          xs="12"
                          sm="2"
                          lg="1"
                          className="d-flex flex-column mb-2 mb-lg-0 align-items-start align-items-end align-items-sm-center order-2 order-lg-last justify-content-lg-center"
                        >
                          <div className="text-muted text-small d-lg-none">Cargo</div>
                          <span className="text-alternative text-primary">
                            <span className="text-small text-muted text-primary">$</span>
                            {fmDecimal.from(parseInt(overweight.charge, 10))}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              )
            )}
          {/* List overweight Items End */}
        </>
      )}
    </>
  );
};
export default OrderDetail;
