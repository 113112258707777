import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
// utils
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
// components
import ButtonRequest from '../../../../components/buttons/ButtonRequest';
import LoadingDots from '../../../../components/loading/LoadingRed';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';
// api
import { calculateOverweight, addOverweight, addAdditionalCharge, getChargeConcepts } from '../../../api/Api-admin';

const ShippingModal = ({ data, isModalOpen, setIsModalOpen, title, type, setUpdatePage }) => {
  const labelId = data.shipping_label_id;
  const crmId = data.crm_user;

  const [chargeConceptList, setChargeConceptList] = useState([]);
  const [nameConcept, setNameConcept] = useState('Concepto');
  const [codeConcept, setCodeConcept] = useState('');
  // const [overweight, setOverweight] = useState('');
  const [weightDiff, setWeightDiff] = useState(''); // DIFERENCIA DE KILOS
  const [realWeight, setRealWeight] = useState(''); // PESO REAL
  const [overweightCharges, setOverweightCharges] = useState(null); // AQUI SE GUARDA LA RESPUESTA DEL CALCULO DE SOBREPESO
  const [isCalculateOverweight, setIsCalculateOverweight] = useState(false);
  const [chargeAmount, setChargeAmount] = useState('');
  const [referenceTrace, setReferenceTrace] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);

  const optionalDecimalRegex = /^\d*\.?\d*$/;
  const numberRegex = /^[0-9]+$/i;

  const closeModal = () => {
    setWeightDiff('');
    setRealWeight('');
    setChargeAmount('');
    setNameConcept('Concepto');
    setCodeConcept('');
    setIsModalOpen(false);
    setUpdatePage(true);
  };
  const handleCalculateOverweight = async () => {
    try {
      setIsCalculateOverweight(true);
      const response = await calculateOverweight(labelId, weightDiff, realWeight);
      // console.log('response calculate overweight:', response);
      if (response.overweight_charge_info !== undefined) {
        setOverweightCharges(response.overweight_charge_info);
      } else if (response.error) {
        handleAPIError(response);
        setOverweightCharges(null);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsCalculateOverweight(false); // Esto siempre se ejecuta al final
      // setIsAddChargerLoader(false); // active loader in the button
    }
  };
  const handlerAddOverweight = async () => {
    try {
      if (weightDiff || realWeight) {
        // console.log(`agregar sobrpesos de ${overweight} kg, en la guai numeror: ${labelId}`);
        const response = await addOverweight(labelId, weightDiff, realWeight);
        // console.log('add overweight res:', response);
        if (response.overweight_charge !== undefined) {
          ToastSuccess('Se agrego sobrepeso correctamente ⭐');
          closeModal();
        } else if (response.error) {
          handleAPIError(response);
        }
      } else {
        ToastErrorCath('Error: Diferencia de kilos o peso real es requerido');
      }
    } catch (error) {
      handleCatchError(error);
      // console.log('error catch');
    } finally {
      setIsAddChargerLoader(false); // disable loader in the button
    }
  };
  const handlerAddCharges = async () => {
    try {
      setIsAddChargerLoader(true);
      // console.log('DATOS CA', crmId, labelId, chargeAmount, codeConcept, referenceTrace);
      if (chargeAmount && codeConcept) {
        // Validar si nameConcept es 'Retorno Guia' y si referenceTrace está vacío
        if (nameConcept === 'Retorno Guia' && !referenceTrace) {
          ToastErrorCath('El campo referencia es obligatorio para el concepto "Retorno Guia".');
          return; // Detenemos la ejecución si la validación falla
        }
        const response = await addAdditionalCharge(crmId, labelId, chargeAmount, codeConcept, referenceTrace);
        // console.log('agregar cargo res:', response);
        if (response.client_id !== undefined) {
          ToastSuccess('Se aplico cargo correctamente ⭐');
          closeModal();
        } else if (response.error) {
          handleAPIError(response);
        }
      } else {
        ToastErrorCath('Error: Concepto y monto son requeridos');
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsAddChargerLoader(false); // disable loader in the button
    }
  };
  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsAddChargerLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  const handleButtonClick = () => {
    handleButtonAction();

    if (type === 'ow') {
      handlerAddOverweight();
    } else {
      handlerAddCharges();
    }
  };
  useEffect(() => {
    // REDUCES_BALANCE   INCREASES_BALANCE
    if (type === 'ad') {
      getChargeConcepts('REDUCES_BALANCE').then((response) => {
        setChargeConceptList(response.list);
      });
    }
  }, [type]);
  return (
    <Modal show={isModalOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column flex-sm-row">
          <span className="w-sm-50">
            <p className="text-small text-muted mb-2">ENVIO</p>
            <div className="d-flex align-items-center lh-1-25">Usuario</div>
            <div className="text-primary">{data.razon_social.toUpperCase()}</div>
            <div className="d-flex align-items-center lh-1-25 ">ID Envío</div>
            <div className="text-primary">{labelId}</div>
            <div className="d-flex align-items-center lh-1-25 ">Servicio</div>
            <div className="text-primary">{data.shipping_code.replace(/_+/g, ' ')}</div>
            {nameConcept === 'Retorno Guia' && (
              <>
                {/* <div className="d-flex align-items-center lh-1-25">ID Envío Retorno</div> */}
                <Form.Control
                  type="text"
                  placeholder="ID Envío Retorno"
                  style={{ fontFamily: 'Montserrat' }}
                  className="me-2 mt-3 "
                  value={referenceTrace}
                  onChange={(e) => {
                    setReferenceTrace(e.target.value);
                  }}
                />
              </>
            )}
            {type === 'ow' && (
              <>
                {isCalculateOverweight ? (
                  <div className="mt-5 bg-infoS">
                    <LoadingDots />
                  </div>
                ) : (
                  <>
                    {overweightCharges && (
                      <>
                        <p className="text-small text-muted mt-2 mb-1">SOBREPESO</p>
                        <div className="text-primary">
                          <span className="text-medium text-alternate pe-2"> Kilos a Cobrar:</span>
                          {overweightCharges?.kg_to_collect}
                        </div>
                        <div className="text-primary">
                          <span className="text-medium text-alternate pe-2"> Costo Sobrepeso:</span>
                          {overweightCharges?.extra_kg_cost}
                        </div>
                        <div className="text-primary">
                          <span className="text-medium text-alternate pe-2"> Cargo con IVA:</span>
                          {overweightCharges?.overweight_charge_with_iva}
                        </div>
                        <div className="text-primary">
                          <span className="text-medium text-alternate pe-2"> Peso Real:</span>
                          {overweightCharges?.real_weight}
                        </div>
                        <div className="text-primary">
                          <span className="text-medium text-alternate pe-2"> Kilos Amparados:</span>
                          {overweightCharges?.kg_coverage_for_shipping}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </span>
          <span className="w-sm-50 ps-sm-4">
            <p className="text-small text-muted mb-2 pt-4 pt-sm-0">PAQUETE</p>
            <div className="d-flex align-items-center lh-1-25">Medidas</div>
            <div className="text-primary">{`${data.parcel?.depth} x ${data.parcel?.width} x ${data.parcel?.height} `}</div>
            <div className="d-flex align-items-center lh-1-25 mt-sm-4">Peso declarado</div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-2"> Físico:</span>
              {`${data.parcel.weight} kg`}
            </div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-3"> Volumétrico:</span>
              {`${data.parcel.volumetric_weight} kg`}
            </div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-3"> Final:</span>
              {`${data.parcel.total_weight} kg`}
            </div>
          </span>
        </div>
      </Modal.Body>
      {/* py-4 ps-2 */}
      {/* flex-column flex-sm-row  align-items-center align-items-sm-end flex-column flex-sm-row */}
      <Modal.Footer className="bg-infox">
        <div className="d-flex flex-wrap justify-content-end bg-warningd">
          {type === 'ad' && (
            <>
              <Dropdown className="d-inline-block mb-2">
                <Dropdown.Toggle
                  variant="foreground-alternate"
                  className="shadow d-flex justify-content-between align-items-center ps-2 ps-sm-3 pe-3"
                  style={{ width: '290px' }}
                >
                  {nameConcept}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow " style={{ maxHeight: '250px', overflowY: 'scroll', fontFamily: 'Montserrat' }}>
                  {chargeConceptList.length > 0 &&
                    chargeConceptList.map((element, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setNameConcept(element.name_concept);
                          setCodeConcept(element.code_concept);
                        }}
                      >
                        {element.name_concept}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat', width: '80px' }}
                className="nav-shadow ms-2 mb-2"
                placeholder="$0"
                value={chargeAmount}
                onChange={(e) => {
                  // updateField(e.target.value);
                  const { value } = e.target;
                  if (value === '' || optionalDecimalRegex.test(value)) {
                    setChargeAmount(value);
                  }
                }}
              />
            </>
          )}
          {type === 'ow' && (
            <>
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat', width: '96px' }}
                className="me-2 mb-2"
                placeholder="Peso Real"
                value={realWeight}
                onChange={(e) => {
                  setWeightDiff('');
                  const { value } = e.target;
                  if (value === '' || numberRegex.test(value)) {
                    setRealWeight(value);
                  }
                }}
              />
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat', width: '96px' }}
                className="mb-2"
                // className="nav-shadow mb-3 ms-1 mb-sm-0"
                placeholder="Diferencia"
                value={weightDiff}
                onChange={(e) => {
                  setRealWeight('');
                  const { value } = e.target;
                  if (value === '' || numberRegex.test(value)) {
                    setWeightDiff(value);
                  }
                }}
              />
              <Button variant="primary" style={{ height: '36px' }} className="w-auto ms-2 mb-2" onClick={handleCalculateOverweight}>
                Calcular
              </Button>
            </>
          )}
          <ButtonRequest
            title="Aplicar"
            titleLoading="Cargando"
            handlerFn={handleButtonClick}
            isButtonDisabled={isButtonDisabled}
            isLoading={isAddChargerLoader}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ShippingModal;
