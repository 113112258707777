import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row } from 'react-bootstrap';

// api
import { getAdminShippingsByUserId } from '../../../../../api/Api-admin';
// components
import Loading from '../../../../../../components/loading/LoadingRed';
import ShippingRow from './components/ShippingRow';
import ShippingCard from './components/ShippingCard';

const Shippings = ({ crmId, userName }) => {
  const [shippings, setShippings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalShippings, setTotalShippings] = useState(0);
  const [error, setError] = useState('');

  const handlerGetShippingByUser = async () => {
    try {
      const response = await getAdminShippingsByUserId(1, 5, crmId);
      // console.log('response:', response);
      if (response.shippings !== undefined) {
        setShippings(response.shippings);
        setTotalShippings(response.total_items);
      } else if (response.error.admin) {
        const errorMessage = response.error.admin[0].trim();
        setError(errorMessage);
        if (errorMessage.includes('No se encontraron envíos con los parámetros especificados.')) {
          setError('');
          setShippings([]);
        }
      }
    } catch {
      setError('Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handlerGetShippingByUser();
  }, []);
  // console.log(shippings)
  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {error && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>{error}</h3>
        </Row>
      )}
      {shippings?.length === 0 && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>No hay historial de envíos.</h3>
        </Row>
      )}
      {totalShippings > 5 && (
        <NavLink to={`/users-all/shippings/${crmId}/${userName}`} className="mb-3 cursor-pointer text-decoration-underline text-end">
          Ver todos
        </NavLink>
      )}
      {shippings?.length > 0 && <ShippingRow />}
      {shippings?.map((shipping, index) => (
        <ShippingCard data={shipping} key={index} />
      ))}
    </>
  );
};

export default Shippings;
