import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';

const ButtonRequest = ({ title, titleLoading, handlerFn, isButtonDisabled, isLoading }) => {
  return (
    <Button
      variant="primary"
      size="lg"
      className="mx-2"
      // className="me-2 w-auto ms-2 mb-2 d-flex justify-content-between align-items-center"
      onClick={() => handlerFn()}
      style={{ height: '36px' }}
    >
      {isLoading && <ClipLoader disabled={isButtonDisabled} color="#FFFFFF" loading={isLoading} size={10} />}
      {isLoading ? titleLoading : title}
      {/* {isLoader && (
        <Spinner
          as="span"
          animation="border"
          role="status"
          disabled={isButtonDisabled}
          aria-hidden="true"
          style={{
            width: '13px',
            height: '13px',
            borderWidth: '1px',
            color: '#FFFF',
          }}
        />
      )}
      {title} */}
    </Button>
  );
};
export default ButtonRequest;
