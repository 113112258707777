import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Dropdown, ListGroup, ListGroupItem, InputGroup, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import * as Yup from 'yup';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// components
import HtmlHead from 'components/html-head/HtmlHead';
import LoadingCar from '../../../components/loading/LoadingCar';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';

// api
import { getZipCode, getRates, createAddress, createParcel, getAddressesDirectory, getParcelDirectory, getAddresses, getParcels } from '../../../api/Api-user';
// reducers
import { setSenderData, setSenderDataError, setSenderDataValidate } from '../../../slices/shipping/senderDataSlice';
import { setReceiverData, setReceiverDataError, setReceiverDataValidate } from '../../../slices/shipping/receiverDataSlice';
import { setParcelData, setParcelDataError } from '../../../slices/shipping/parcelDataSlice';
import { setRatesData } from '../../../slices/shipping/ratesDataSlice';

const ShippingForm = () => {
  const title = 'Enviar';
  const description = 'Enviar Plataforma Autopaquete';
  const dispatch = useDispatch();
  const history = useHistory();
  // Global states
  const { currentUser } = useSelector((state) => state.auth);
  // - Global State from Store.js form Values
  const { senderData } = useSelector((state) => state.shipping_sender);
  const { receiverData } = useSelector((state) => state.shipping_receiver);
  const { parcelData } = useSelector((state) => state.shipping_parcel);
  // - Local States
  const [isLoading, setIsLoading] = useState(false);
  const [pieces, setPieces] = useState(1);
  const [saveReceiverAddress, setSaveReceiverAddress] = useState(false);
  const [saveSenderAddress, setSaveSenderAddress] = useState(false);
  const [saveParcel, setSaveParcel] = useState(false);
  // Start Directory states
  const [loadingAddressFrom, setLoadingAddressFrom] = useState(false);
  const [loadingAddressTo, setLoadingAddressTo] = useState(false);
  const [loadingParcel, setLoadingParcel] = useState(false);

  const [getAllAddressesFrom, setGetAllAddressesFrom] = useState(false);
  const [getAllAddressesTo, setGetAllAddressesTo] = useState(false);
  const [getAllParcels, setGetAllParcels] = useState(false);
  const [directoryFrom, setDirectoryFrom] = useState(null);
  const [directoryTo, setDirectoryTo] = useState(null);
  const [directoryParcels, setDirectoryParcels] = useState(null);
  // End Directory states
  // - Use references to do scroll on this page
  const formAddressFromRef = useRef(null);
  const formAddressToRef = useRef(null);
  const formParcelRef = useRef(null);
  const multiPiecesOk = currentUser.multipieces;
  // - Schema Validations Start
  const validationAddressesSchema = Yup.object().shape({
    // company and references are not required
    name: Yup.string().min(3, 'El nombre debe tener al menos 3 caracteres.').required('El nombre es obligatorio.'),
    street: Yup.string().min(3, 'La calle debe tener al menos 3 caracteres.').required('La calle es obligatoria.'),
    streetNumber: Yup.string().required('El número de calle es obligatorio.'),
    neighborhood: Yup.string().min(3, 'La colonia debe tener al menos 3 caracteres.').required('La colonia es obligatoria.'),
    zip: Yup.string().min(5, 'El código postal debe tener 5 caracteres.').required('El código postal es obligatorio.'),
    phone: Yup.string().min(10, 'El número de teléfono debe tener 10 caracteres.').required('El número de teléfono es obligatorio.'),
  });
  const validationParcelSchema = Yup.object().shape({
    // name is not required
    product_description: Yup.string().min(3, 'La descripción debe tener mínimo 3 caracteres.').required('La descripción del producto es obligatoria.'),
    height: Yup.string().required('Alto, largo y ancho son requeridos.'),
    width: Yup.string().required('Alto, largo y ancho son requeridos.'),
    depth: Yup.string().required('Alto, largo y ancho son requeridos.'),
    weight: Yup.string().required('El peso del paquete es obligatorio.'),
  });
  // - Schema Validations End
  // remove accents
  const cleanData = (str) => {
    let cleanedStr;
    // Quitar
    if (typeof str === 'string') {
      cleanedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      // Eliminar caracteres especiales, dejando solo letras y números
      cleanedStr = cleanedStr.replace(/[^a-zA-Z0-9 ]/g, '');
    }
    return cleanedStr;
  };
  // - Data Validation and Updating States with Reducers
  const updateField = (reducer, data, point, max) => {
    if (
      point === 'phone' ||
      point === 'zip' ||
      point === 'weight' ||
      point === 'height' ||
      point === 'width' ||
      point === 'depth' ||
      point === 'product_value_shipping'
    ) {
      // save numbers only
      if (data === '' || /^[0-9]+$/i.test(data)) dispatch(reducer({ data, point }));
    } else {
      // save string
      // console.log('Data que da error:', data, 'tipo', typeof data, 'campo:', point);
      // eslint-disable-next-line no-lonely-if
      if (typeof data === 'string') {
        const cleanedData = cleanData(data).substring(0, max);
        dispatch(reducer({ data: cleanedData, point }));
      }
    }
  };
  // - CP validation Start
  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (senderData.fields.zip.length === 5) {
      getZipCode(senderData.fields.zip).then((res) => {
        // console.log("zipcode response:", res)
        if (res.length > 0) {
          dispatch(setSenderData({ data: res[0].city, point: 'city' }));
          dispatch(setSenderData({ data: res[0].state, point: 'state' }));
          dispatch(setSenderDataError({ data: '', point: 'zip' }));
          dispatch(setSenderDataValidate({ point: 'zip', data: true }));
        } else {
          dispatch(setSenderDataError({ data: 'Código postal invalido', point: 'zip' }));
          dispatch(setSenderDataValidate({ point: 'zip', data: false }));
        }
      });
    } else {
      dispatch(setSenderDataError({ data: '', point: 'zip' }));
      dispatch(setSenderData({ data: '', point: 'city' }));
      dispatch(setSenderData({ data: { name: '', key: '' }, point: 'state' }));
      dispatch(setSenderDataValidate({ point: 'zip', data: false }));
    }
  }, [senderData.fields.zip, dispatch]);
  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (receiverData.fields.zip?.length === 5) {
      getZipCode(receiverData.fields.zip).then((res) => {
        // console.log("zipcode from response:", res)
        if (res?.length > 0) {
          dispatch(setReceiverData({ data: res[0].city, point: 'city' }));
          dispatch(setReceiverData({ data: res[0].state, point: 'state' }));
          dispatch(setReceiverDataError({ data: '', point: 'zip' }));
          dispatch(setReceiverDataValidate({ point: 'zip', data: true }));
        } else {
          dispatch(setReceiverDataError({ data: 'Código postal invalido', point: 'zip' }));
          dispatch(setReceiverDataValidate({ point: 'zip', data: false }));
        }
      });
    } else {
      dispatch(setReceiverDataError({ data: '', point: 'zip' }));
      dispatch(setReceiverData({ data: '', point: 'city' }));
      dispatch(setReceiverData({ data: { name: '', key: '' }, point: 'state' }));
      dispatch(setReceiverDataValidate({ point: 'zip', data: false }));
    }
  }, [receiverData.fields.zip, dispatch]);
  // - CP validation End
  useEffect(() => {
    // - useEffect observes checkbox changes and set the productValue
    // - productValue it will be sent int the request
    // if parcel will be insured and theres is a value of product_value_shipping
    if (parcelData.fields.insurance && parcelData.fields.product_value_shipping > 0) {
      // add value to productValue field
      dispatch(setParcelData({ point: 'product_value', data: parseInt(parcelData.fields.product_value_shipping, 10) }));
    } else if (!parcelData.fields.insurance) {
      dispatch(setParcelData({ point: 'product_value', data: 0 }));
      // clean up any bug on product_value_shipping input
      dispatch(setParcelDataError({ point: 'product_value_shipping', data: false }));
    } else if (!parcelData.fields.product_value_shipping) {
      dispatch(setParcelData({ point: 'product_value', data: 0 }));
      dispatch(setParcelData({ point: 'product_value_shipping', data: null }));
    }
  }, [parcelData.fields.insurance, parcelData.fields.product_value_shipping, dispatch]);
  // - If the parcel will not be saved, the errors are eliminated
  useEffect(() => {
    if (!saveParcel) {
      dispatch(setParcelDataError({ data: false, point: 'name' }));
    }
  }, [saveParcel, dispatch]);
  // - If sender address is autofill correctly, eliminate any errors (autofill function)
  useEffect(() => {
    if (
      senderData.fields.name ||
      senderData.fields.street ||
      senderData.fields.streetNumber ||
      senderData.fields.neighborhood ||
      senderData.fields.zip ||
      senderData.fields.phone
    ) {
      dispatch(setSenderDataError({ point: 'name', data: false }));
      dispatch(setSenderDataError({ point: 'streetNumber', data: false }));
      dispatch(setSenderDataError({ point: 'street', data: false }));
      dispatch(setSenderDataError({ point: 'neighborhood', data: false }));
      dispatch(setSenderDataError({ point: 'zip', data: false }));
      dispatch(setSenderDataError({ point: 'phone', data: false }));
    }
  }, [
    senderData.fields.name,
    // senderData.fields.company,
    senderData.fields.street,
    senderData.fields.streetNumber,
    senderData.fields.neighborhood,
    // senderData.fields.placeRef,
    senderData.fields.zip,
    senderData.fields.phone,
    dispatch,
  ]);
  // - If receiver address is autofill correctly, eliminate any errors
  useEffect(() => {
    if (
      receiverData.fields.name ||
      receiverData.fields.company ||
      receiverData.fields.street ||
      receiverData.fields.streetNumber ||
      receiverData.fields.neighborhood ||
      receiverData.fields.placeRef ||
      receiverData.fields.zip ||
      receiverData.fields.phone
    ) {
      dispatch(setReceiverDataError({ point: 'name', data: false }));
      dispatch(setReceiverDataError({ point: 'company', data: false }));
      dispatch(setReceiverDataError({ point: 'streetNumber', data: false }));
      dispatch(setReceiverDataError({ point: 'street', data: false }));
      dispatch(setReceiverDataError({ point: 'neighborhood', data: false }));
      dispatch(setReceiverDataError({ point: 'placeRef', data: false }));
      dispatch(setReceiverDataError({ point: 'zip', data: false }));
      dispatch(setReceiverDataError({ point: 'phone', data: false }));
    }
  }, [
    receiverData.fields.name,
    receiverData.fields.company,
    receiverData.fields.street,
    receiverData.fields.streetNumber,
    receiverData.fields.neighborhood,
    receiverData.fields.placeRef,
    receiverData.fields.zip,
    receiverData.fields.phone,
    dispatch,
  ]);
  // - If parcel is autofill correctly, eliminate any errors
  useEffect(() => {
    if (
      parcelData.fields.name ||
      parcelData.fields.product_description ||
      parcelData.fields.width ||
      parcelData.fields.height ||
      parcelData.fields.depth ||
      parcelData.fields.weight
    ) {
      dispatch(setParcelDataError({ point: 'name', data: false }));
      dispatch(setParcelDataError({ point: 'product_description', data: false }));
      dispatch(setParcelDataError({ point: 'height', data: false }));
      dispatch(setParcelDataError({ point: 'width', data: false }));
      dispatch(setParcelDataError({ point: 'depth', data: false }));
      dispatch(setParcelDataError({ point: 'weight', data: false }));
    }
  }, [
    parcelData.fields.name,
    parcelData.fields.product_description,
    parcelData.fields.width,
    parcelData.fields.height,
    parcelData.fields.depth,
    parcelData.fields.weight,
    dispatch,
  ]);
  // - If parcel is autofill correctly, eliminate any errors
  useEffect(() => {
    dispatch(setParcelData({ data: pieces, point: 'multi_pieces' }));
  }, [pieces, dispatch]);
  // - Handler Get All Addresses From
  useEffect(() => {
    if (getAllAddressesFrom) {
      setLoadingAddressFrom(true);
      getAddresses(1, 80).then((response) => {
        // console.log('res addresses from:', response.address);
        setDirectoryFrom(response.address);
        setLoadingAddressFrom(false);
      });
    } else {
      setDirectoryFrom(null);
    }
  }, [getAllAddressesFrom]);
  // - Handler Get All Addresses To
  useEffect(() => {
    if (getAllAddressesTo) {
      setLoadingAddressTo(true);
      getAddresses(1, 80).then((response) => {
        // console.log('res addresses to:', response.address);
        setDirectoryTo(response.address);
        setLoadingAddressTo(false);
      });
    } else {
      setDirectoryTo(null);
    }
  }, [getAllAddressesTo]);
  // - Handler Get All Parcels
  useEffect(() => {
    if (getAllParcels) {
      setLoadingParcel(true);
      getParcels(1, 50).then((response) => {
        // console.log('result', response);
        setDirectoryParcels(response?.parcel);
        setLoadingParcel(false);
      });
    } else {
      setDirectoryParcels(null);
    }
  }, [getAllParcels]);

  // - Get Address to sender address form
  const handlerDirectoryFrom = (string) => {
    setGetAllAddressesFrom(false); // dropdown regresa a su estado inicial
    setLoadingAddressFrom(true);
    setTimeout(() => {
      setLoadingAddressFrom(false);
    }, 2000);
    getAddressesDirectory(string).then((result) => {
      // console.log('resultado peticion addresss from:', result.address);
      if (result.address?.length >= 1) {
        setDirectoryFrom(result.address);
        setLoadingAddressFrom(false);
      }
    });
  };
  // - Get Address to receiver address form
  const handlerDirectoryTo = (string) => {
    setGetAllAddressesTo(false); // dropdown regresa a su estado inicial
    setLoadingAddressTo(true);
    setTimeout(() => {
      setLoadingAddressTo(false);
    }, 2000);
    getAddressesDirectory(string).then((result) => {
      // console.log('resultado peticion addresss to:', result);
      if (result.address.length >= 1) {
        setDirectoryTo(result.address);
        setLoadingAddressTo(false);
      }
    });
  };
  // - Get Parcels
  const handlerDirectoryParcels = (string) => {
    setGetAllParcels(false); // dropdown regresa a su estado inicial
    setLoadingParcel(true);
    setTimeout(() => {
      setLoadingParcel(false);
    }, 2000);
    getParcelDirectory(string)
      .then((result) => {
        if (result.parcel?.length >= 1) {
          setDirectoryParcels(result.parcel);
        }
      })
      .catch();
  };
  // - Autofill Sender Address Form
  const fillSenderAddress = (address) => {
    setGetAllAddressesFrom(false); // dropdown regresa a su estado inicial
    setDirectoryFrom(null); // delete address directory
    // - Fill inputs
    updateField(setSenderData, address.name, 'name', 29);
    updateField(setSenderData, address.company, 'company', 29);
    updateField(setSenderData, address.street_number, 'streetNumber', 10);
    updateField(setSenderData, address.neighborhood, 'neighborhood', 35);
    updateField(setSenderData, address.zip_code, 'zip', 5);
    updateField(setSenderData, address.street, 'street', 50);
    updateField(setSenderData, address.phone, 'phone', 10);
    updateField(setSenderData, address.reference, 'placeRef', 20);
  };
  // - Autofill Receiver Address Form
  const fillReceiverAddress = (address) => {
    setGetAllAddressesTo(false); // dropdown regresa a su estado inicial
    setDirectoryTo(null); // delete address directory
    // - Fill inputs
    updateField(setReceiverData, address.name, 'name', 29);
    updateField(setReceiverData, address.company, 'company', 29);
    updateField(setReceiverData, address.street_number, 'streetNumber', 10);
    updateField(setReceiverData, address.neighborhood, 'neighborhood', 35);
    updateField(setReceiverData, address.zip_code, 'zip', 5);
    updateField(setReceiverData, address.street, 'street', 50);
    updateField(setReceiverData, address.phone, 'phone', 10);
    updateField(setReceiverData, address.reference, 'placeRef', 20);
  };

  // - Autofill Parcel Form
  const fillParcel = (parcel) => {
    setGetAllParcels(false); // dropdown regresa a su estado inicial
    setDirectoryParcels(null); // delete address directory
    // - Fill inputs
    updateField(setParcelData, parcel.name, 'name', 35);
    updateField(setParcelData, parcel.product_description, 'product_description', 35);
    updateField(setParcelData, parcel.height, 'height', 3);
    updateField(setParcelData, parcel.width, 'width', 3);
    updateField(setParcelData, parcel.depth, 'depth', 3);
    updateField(setParcelData, parcel.weight, 'weight', 2);
    // dispatch(setParcelData({ data: parcel.name, point: 'name' }));
    dispatch(setParcelData({ data: parcel.product_value, point: 'product_value_shipping' }));
    dispatch(setParcelData({ data: parcel.insurance, point: 'insurance' }));
  };

  // - Get Rates
  const handlerQuoter = async () => {
    try {
      const parcel = {
        zipFrom: senderData.fields.zip,
        zipTo: receiverData.fields.zip,
        height: parcelData.fields.height,
        width: parcelData.fields.width,
        depth: parcelData.fields.depth,
        weight: parcelData.fields.weight,
        productValue: parcelData.fields.product_value,
        multi_pieces: parcelData.fields.multi_pieces,
      };
      // console.log('data request rates:', parcel, senderData.fields.zip, receiverData.fields.zip);
      setIsLoading(true);
      const response = await getRates(parcel);
      if (response.zip_from !== undefined) {
        dispatch(setRatesData(response));
        // console.log('respuesta exitosa rates:', response);
        history.push('/envios/enviar/servicios');
      } else if (response.error) {
        // const firstKey = Object.keys(response.error)[0];
        // ToastErrorApi(response.error[firstKey]);
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
      // ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };
  const validateForm = async (e) => {
    e.preventDefault();
    let addressFromOk = false;
    let addressToOk = false;
    let parcelOk = false;
    // - Address from validations
    try {
      await validationAddressesSchema.validate(senderData.fields, { abortEarly: false });
      // console.log('2 address from ok');
      addressFromOk = true;
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        dispatch(setSenderDataError({ data: error.message, point: error.path }));
        formErrors[error.path] = error.message;
      });
      // console.log('errores en addres from:', formErrors);
      formAddressFromRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
    // - Address to validations
    try {
      await validationAddressesSchema.validate(receiverData.fields, { abortEarly: false });
      // console.log('3 address to ok');
      addressToOk = true;
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        dispatch(setReceiverDataError({ data: error.message, point: error.path }));
        formErrors[error.path] = error.message;
      });
      // console.log('errores en addres to:', formErrors);
      formAddressToRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
    // - Parcel validations
    try {
      await validationParcelSchema.validate(parcelData.fields, { abortEarly: false });
      parcelOk = true;
      // - if insurance is true then product Value should not be empty
      if (parcelData.fields.insurance && !parcelData.fields.product_value_shipping) {
        parcelOk = false;
        dispatch(setParcelDataError({ data: 'Campo obligatorio para asegurar el paquete', point: 'product_value_shipping' })); // scroll to error
      }
      // - if saveParcel is true, name should not be empty
      if (saveParcel && !parcelData.fields.name) {
        parcelOk = false;
        dispatch(setParcelDataError({ data: 'Se requiere llenar este campo para guardar el paquete', point: 'name' }));
      }
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        dispatch(setParcelDataError({ data: error.message, point: error.path }));
        formErrors[error.path] = error.message;
      });
      // console.log('errores en parcel:', formErrors);
      formParcelRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
    // console.log('from,', addressFromOk, 'to,', addressToOk, 'parcel,', parcelOk);
    // -If there is sender address to save, saveSenderAddress is the checkbox element
    if (addressFromOk && saveSenderAddress) {
      // handlerCreateAddress(senderData.fields);
      try {
        const result = await createAddress(senderData.fields);
        // console.log('nueva direccion origen: ', result);
        if (result.name !== undefined) {
          setTimeout(() => {
            ToastSuccess('¡Dirección de origen guardada con éxito! ');
          }, 3000);
        } else if (result.error.address) {
          addressFromOk = false;
          ToastErrorCath(`Error: No es posible guardar direccion de origen. ${result.error.address[0]} `);
        }
      } catch (error) {
        // handleCatchError(error);
        // ToastErrorCath();
      }
    }
    // -If there is receiver address to save, saveReceiverAddress is the checkbox element
    if (addressToOk && saveReceiverAddress) {
      // handlerCreateAddress(receiverData.fields);
      try {
        const result = await createAddress(receiverData.fields);
        // console.log('nueva direccion destino: ', result);
        if (result.name !== undefined) {
          setTimeout(() => {
            ToastSuccess('¡Dirección de destino guardada con éxito! ');
          }, 3000);
        } else if (result.error.address) {
          addressToOk = false;
          ToastErrorCath(`Error: No es posible guardar direccion de destino. ${result.error.address[0]} `);
        }
      } catch (error) {
        // handleCatchError(error);
        // ToastErrorCath();
      }
    }
    // -If there is parcel to save, saveParcel is the checkbox element
    if (parcelOk && saveParcel) {
      try {
        const parcel = {
          name: parcelData.fields.name,
          description: parcelData.fields.product_description,
          height: parcelData.fields.height,
          width: parcelData.fields.width,
          depth: parcelData.fields.depth,
          weight: parcelData.fields.weight,
          value: parcelData.fields.product_value,
          insurance: parcelData.fields.insurance,
        };
        const result = await createParcel(parcel);
        // console.log('nuevo paquete: ', result);
        if (result.name !== undefined) {
          setTimeout(() => {
            ToastSuccess('¡Paquete guardado con éxito!');
          }, 3000);
        } else if (result.error.parcel) {
          parcelOk = false;
          ToastErrorCath(`Error: No es posible guardar este paquete. ${result.error.parcel}`);
        }
      } catch (error) {
        // handleCatchError(error);
        // ToastErrorCath();
      }
    }
    if (addressFromOk && addressToOk && parcelOk) {
      // - if forms are ok get rates
      handlerQuoter();
    }
  };
  // console.log('datos origen:', senderData.fields);
  // console.log('PARCEL GLOBAL SCOPE:', parcelData.fields);
  // console.log('* desde form shipping user obj estado global *:', currentUser);

  return (
    <>
      {isLoading && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        {/* Title Start */}
        <div className="page-title-container">
          <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
            <CsLineIcons icon="chevron-left" size="13" />
            <span className="align-middle text-small ms-1">Inicio</span>
          </NavLink>
          <h1 className="mb-0 pb-0 display-4" id="title">
            {title}
          </h1>
        </div>
        {/* Title End */}
        <form onSubmit={validateForm}>
          {/* Start Addresses Forn */}
          <Row>
            {/* Address From */}
            <Col xs="12" className="col-lg order-1 order-lg-0">
              <h2 className="small-title" ref={formAddressFromRef}>
                Dirección origen
              </h2>
              <Card className="mb-5">
                <Card.Body style={{ fontFamily: 'Montserrat' }}>
                  <Form onClick={() => setDirectoryFrom(null)}>
                    <Row className="g-3">
                      {/* START SENDER NAME */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Nombre</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            {loadingAddressFrom ? (
                              <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  borderWidth: '1px',
                                  color: '#850000',
                                }}
                              />
                            ) : (
                              <CsLineIcons icon="user" size="16" className="text-alternate" />
                            )}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={senderData.fields.name}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'name', 29);
                              dispatch(setSenderDataError({ point: 'name', data: false }));
                              handlerDirectoryFrom(e.target.value);
                            }}
                          />
                          <InputGroup.Text className="cursor-pointer" onClick={() => setGetAllAddressesFrom(!getAllAddressesFrom)}>
                            <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
                            {/* <CsLineIcons icon="chevron-top" size="18"/> */}
                          </InputGroup.Text>
                        </InputGroup>
                        {/* - Address List Start */}
                        {directoryFrom && (
                          <ListGroup
                            style={{
                              position: 'absolute',
                              zIndex: 1000,
                              width: '96%',
                              maxHeight: '480px',
                              overflowY: 'scroll',
                              cursor: 'pointer',
                              fontFamily: 'Montserrat',
                              borderRadius: '10px',
                              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                            }}
                          >
                            {directoryFrom.map((address, index) => (
                              <ListGroupItem key={index} className="p-2 ps-3 rounded-0 " onClick={() => fillSenderAddress(address)}>
                                <div className="d-flex align-items-center">
                                  <CsLineIcons icon="home" size="16" className="text-primary" />
                                  <div className="ms-3">
                                    {address.name}
                                    <p className="text-small m-0">{`Calle: ${address.street} Colonia: ${address.neighborhood} cp: ${address.zip_code}`} </p>
                                  </div>
                                </div>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        )}
                        {/* - Address List End */}
                        {senderData.errors.name && (
                          <div className={`invalid-tooltip ${senderData.errors.name ? 'd-block' : 'd-none'}`}>{senderData.errors.name}</div>
                        )}
                      </Col>
                      {/* END SENDER NAME */}
                      {/* START SENDER COMPANY */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Empresa</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="building" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={senderData.fields.company}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'company', 29);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END SENDER COMPANY */}
                      {/* START SENDER STREET */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Calle</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            {/* <i className="bi-signpost"/> */}
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={50}
                            placeholder="máximo 50 caracteres"
                            value={senderData.fields.street}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'street', 50);
                              dispatch(setSenderDataError({ point: 'street', data: false }));
                            }}
                          />
                        </InputGroup>
                        {senderData.errors.street && (
                          <div className={`invalid-tooltip ${senderData.errors.street ? 'd-block' : 'd-none'}`}>{senderData.errors.street}</div>
                        )}
                      </Col>
                      {/* END SENDER STREET */}
                      {/* START SENDER STREET NUMBER */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Número</Form.Label>
                        <span className="text-primary">*</span>
                        <Form.Control
                          type="text"
                          maxLength={10}
                          placeholder="&#x23;"
                          value={senderData.fields.streetNumber}
                          onChange={(e) => {
                            updateField(setSenderData, e.target.value, 'streetNumber', 10);
                            dispatch(setSenderDataError({ point: 'street', data: false }));
                          }}
                        />
                        {senderData.errors.streetNumber && (
                          <div
                            className={`invalid-tooltip ${senderData.errors.streetNumber ? 'd-block' : 'd-none'}`}
                            style={{ width: '80%', maxWidth: '190px' }}
                          >
                            {senderData.errors.streetNumber}
                          </div>
                        )}
                      </Col>
                      {/* END SENDER STREET NUMBER */}
                      {/* START SENDER NEIGHBORHOOD */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Colonia</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={senderData.fields.neighborhood}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'neighborhood', 35);
                              dispatch(setSenderDataError({ point: 'neighborhood', data: false }));
                            }}
                          />
                        </InputGroup>

                        {senderData.errors.neighborhood && (
                          <div className={`invalid-tooltip ${senderData.errors.neighborhood ? 'd-block' : 'd-none'}`}>{senderData.errors.neighborhood}</div>
                        )}
                      </Col>
                      {/* END SENDER NEIGHBORHOOD */}
                      {/* START SENDER ZIP CODE */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Código postal</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="pin" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            maxLength={5}
                            value={senderData.fields.zip}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'zip', 5);
                              dispatch(setSenderDataError({ data: '', point: 'zip' }));
                            }}
                          />
                        </InputGroup>
                        {senderData.errors.zip && (
                          <div className={`invalid-tooltip ${senderData.errors.zip ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {senderData.errors.zip}
                          </div>
                        )}
                      </Col>
                      {/* END SENDER ZIP CODE */}
                      <Col sm="7">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control type="text" readOnly value={senderData.fields.city} />
                      </Col>
                      <Col sm="5">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control type="text" readOnly value={senderData.fields.state.name} />
                      </Col>
                      {/* START SENDER REFER */}
                      <Col sm="7">
                        <Form.Label>Referencias</Form.Label>
                        {/* <div style={{ position: 'relative' }}>                       </div> */}
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="destination" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            maxLength={20}
                            type="text"
                            placeholder="máximo 20 caracteres"
                            value={senderData.fields.placeRef}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'placeRef', 20);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END SENDER REFER */}
                      {/* START SENDER PHONE */}
                      <Col sm="5" className="position-relative">
                        <Form.Label>Teléfono</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="phone" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={10}
                            placeholder="10 dígitos"
                            value={senderData.fields.phone}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'phone', 10);
                              dispatch(setSenderDataError({ point: 'phone', data: false }));
                            }}
                          />
                        </InputGroup>

                        {senderData.errors.phone && (
                          <div className={`invalid-tooltip ${senderData.errors.phone ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {senderData.errors.phone}
                          </div>
                        )}
                      </Col>
                      {/* END SENDER PHONE */}
                    </Row>
                    <Row>
                      <Col lg="6">
                        <OverlayTrigger
                          delay={{ show: 500, hide: 0 }}
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Selecciona si deseas guardar esta dirección de origen</Tooltip>}
                        >
                          <div className="form-check mt-4 mb-4">
                            <input
                              type="checkbox"
                              className="form-check-input cursor-pointer"
                              name="sender"
                              checked={saveSenderAddress}
                              onClick={() => {
                                setSaveSenderAddress(!saveSenderAddress);
                              }}
                            />
                            <label className="form-label">Guardar dirección</label>
                          </div>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              {/* Address  From End */}
            </Col>
            {/* Address To */}
            <Col xs="12" className="col-lg order-1 order-lg-0">
              <h2 className="small-title" ref={formAddressToRef}>
                Dirección destino
              </h2>
              <Card className="mb-5">
                <Card.Body style={{ fontFamily: 'Montserrat' }}>
                  <Form onClick={() => setDirectoryTo(null)}>
                    <Row className="g-3">
                      {/* START RECEIVER NAME */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Nombre</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            {loadingAddressTo ? (
                              <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  borderWidth: '1px',
                                  color: '#850000',
                                }}
                              />
                            ) : (
                              <CsLineIcons icon="user" size="16" className="text-alternate" />
                            )}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={receiverData.fields.name}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'name', 29);
                              dispatch(setReceiverDataError({ point: 'name', data: false }));
                              handlerDirectoryTo(e.target.value);
                            }}
                          />
                          <InputGroup.Text className="cursor-pointer" onClick={() => setGetAllAddressesTo(!getAllAddressesTo)}>
                            <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
                            {/* <CsLineIcons icon="chevron-top" size="18"/> */}
                          </InputGroup.Text>
                        </InputGroup>
                        {/* - Address List Start */}
                        {directoryTo && (
                          <ListGroup
                            style={{
                              position: 'absolute',
                              zIndex: 1000,
                              width: '96%',
                              maxHeight: '480px',
                              overflowY: 'scroll',
                              cursor: 'pointer',
                              fontFamily: 'Montserrat',
                              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                            }}
                          >
                            {directoryTo.map((address, index) => (
                              <ListGroupItem key={index} className="p-2 ps-3 rounded-0 " onClick={() => fillReceiverAddress(address)}>
                                <div className="d-flex align-items-center">
                                  <CsLineIcons icon="pin" size="16" className="text-primary" />
                                  <div className="ms-2">
                                    {address.name}
                                    <p className="text-small m-0">{`Calle: ${address.street} Colonia: ${address.neighborhood} cp: ${address.zip_code}`} </p>
                                  </div>
                                </div>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        )}
                        {/* - Address List End */}
                        {receiverData.errors.name && (
                          <div className={`invalid-tooltip ${receiverData.errors.name ? 'd-block' : 'd-none'}`}>{receiverData.errors.name}</div>
                        )}
                      </Col>
                      {/* END RECEIVER NAME */}
                      {/* START RECEIVER COMPANY */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Empresa</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="building" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={receiverData.fields.company}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'company', 29);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END RECEIVER COMPANY */}
                      {/* START RECEIVER  STREET */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Calle</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={50}
                            placeholder="máximo 50 caracteres"
                            value={receiverData.fields.street}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'street', 50);
                              dispatch(setReceiverDataError({ point: 'street', data: false }));
                            }}
                          />
                        </InputGroup>
                        {receiverData.errors.street && (
                          <div className={`invalid-tooltip ${receiverData.errors.street ? 'd-block' : 'd-none'}`}>{receiverData.errors.street}</div>
                        )}
                      </Col>
                      {/* START RECEIVER  STREET */}
                      {/* START RECEIVER  STREET NUMBER */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Número</Form.Label>
                        <span className="text-primary">*</span>
                        <Form.Control
                          type="text"
                          maxLength={10}
                          placeholder="&#x23;"
                          value={receiverData.fields.streetNumber}
                          onChange={(e) => {
                            updateField(setReceiverData, e.target.value, 'streetNumber', 10);
                            dispatch(setReceiverDataError({ point: 'streetNumber', data: false }));
                          }}
                        />

                        {receiverData.errors.streetNumber && (
                          <div
                            className={`invalid-tooltip ${receiverData.errors.streetNumber ? 'd-block' : 'd-none'}`}
                            style={{ width: '80%', maxWidth: '190px' }}
                          >
                            {receiverData.errors.streetNumber}
                          </div>
                        )}
                      </Col>
                      {/* END RECEIVER  STREET NUMBER */}
                      {/* START RECEIVER NEIGHBORBOOD */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Colonia</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={receiverData.fields.neighborhood}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'neighborhood', 35);
                              dispatch(setReceiverDataError({ point: 'neighborhood', data: false }));
                            }}
                          />
                        </InputGroup>

                        {receiverData.errors.neighborhood && (
                          <div className={`invalid-tooltip ${receiverData.errors.neighborhood ? 'd-block' : 'd-none'}`}>{receiverData.errors.neighborhood}</div>
                        )}
                      </Col>
                      {/* END RECEIVER NEIGHBORBOOD */}
                      {/* START RECEIVER ZIP CODE */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Código postal</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="pin" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            maxLength={5}
                            value={receiverData.fields.zip}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'zip', 5);
                              dispatch(setReceiverDataError({ data: '', point: 'zip' }));
                            }}
                          />
                        </InputGroup>

                        {receiverData.errors.zip && (
                          <div className={`invalid-tooltip ${receiverData.errors.zip ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {receiverData.errors.zip}
                          </div>
                        )}
                      </Col>
                      {/* END RECEIVER ZIP CODE */}

                      <Col sm="7">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control type="text" readOnly value={receiverData.fields.city} />
                      </Col>
                      <Col sm="5">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control type="text" readOnly value={receiverData.fields.state.name} />
                      </Col>
                      {/* START RECEIVER REFER */}
                      <Col sm="7">
                        <Form.Label>Referencias</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="destination" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={20}
                            placeholder="máximo 20 caracteres"
                            value={receiverData.fields.placeRef}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'placeRef', 20);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END RECEIVER REFER */}
                      {/* START RECEIVER PHONE */}
                      <Col sm="5" className="position-relative">
                        <Form.Label>Teléfono</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="phone" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            // id="phone"
                            type="text"
                            maxLength={10}
                            placeholder="10 dígitos"
                            value={receiverData.fields.phone}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'phone', 10);
                              dispatch(setReceiverDataError({ point: 'phone', data: false }));
                            }}
                          />
                        </InputGroup>
                        {receiverData.errors.phone && (
                          <div className={`invalid-tooltip ${receiverData.errors.phone ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {receiverData.errors.phone}
                          </div>
                        )}
                      </Col>
                      {/* END RECEIVER PHONE */}
                    </Row>
                    <Row>
                      <Col sm="6" lg="6">
                        <OverlayTrigger
                          delay={{ show: 500, hide: 0 }}
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Selecciona si deseas guardar esta dirección de destino</Tooltip>}
                        >
                          <div className="form-check mt-4 mb-4">
                            <input
                              type="checkbox"
                              className="form-check-input cursor-pointer"
                              name="receiver"
                              checked={saveReceiverAddress}
                              onClick={() => {
                                setSaveReceiverAddress(!saveReceiverAddress);
                              }}
                            />
                            <label className="form-label">Guardar dirección</label>
                          </div>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              {/* Address End */}
            </Col>
          </Row>
          {/* End Addresses Form */}
          {/* Start Package  */}
          <Row>
            <Col xs="12" className="col-lg order-1 order-lg-0">
              <h2 className="small-title" ref={formParcelRef}>
                Paquete
              </h2>
              <Card className="sh-lg-50 sh-lg-60 mb-3">
                <Card.Body style={{ fontFamily: 'Montserrat' }}>
                  <Form onClick={() => setDirectoryParcels(null)}>
                    <Row className="g-3">
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Nombre</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            {loadingParcel ? (
                              <Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                                style={{
                                  width: '10px',
                                  height: '10px',
                                  borderWidth: '1px',
                                  color: '#850000',
                                }}
                              />
                            ) : (
                              <CsLineIcons icon="box" size="16" className="text-alternate" />
                            )}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={parcelData.fields.name}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'name', 35);
                              dispatch(setParcelDataError({ point: 'name', data: false }));
                              handlerDirectoryParcels(e.target.value);
                            }}
                          />
                          <InputGroup.Text className="cursor-pointer" onClick={() => setGetAllParcels(!getAllParcels)}>
                            <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
                            {/* <CsLineIcons icon="chevron-top" size="18"/> */}
                          </InputGroup.Text>
                        </InputGroup>
                        {/* - Parcels Liste Start */}
                        {directoryParcels && (
                          <ListGroup
                            style={{
                              position: 'absolute',
                              zIndex: 1000,
                              width: '96%',
                              maxHeight: '300px',
                              overflowY: 'scroll',
                              cursor: 'pointer',
                              fontFamily: 'Montserrat',
                              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                            }}
                          >
                            {directoryParcels.map((parcel, index) => (
                              <ListGroupItem key={index} className="p-2 ps-3 rounded-0 " onClick={() => fillParcel(parcel)}>
                                <div className="d-flex align-items-center">
                                  <CsLineIcons icon="box" size="16" className="text-primary" />
                                  <div className="ms-3">
                                    {parcel.name}
                                    <p className="text-small m-0">
                                      {`Dimensiones: ${parcel.depth} x ${parcel.width} x ${parcel.height}  Kg: ${parcel.weight} `}{' '}
                                    </p>
                                  </div>
                                </div>
                              </ListGroupItem>
                            ))}
                          </ListGroup>
                        )}
                        {/* - Parcels List End */}
                        {parcelData.errors.name && (
                          <div className={`invalid-tooltip ${parcelData.errors.name ? 'd-block' : 'd-none'}`}>{parcelData.errors.name}</div>
                        )}
                      </Col>
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Descripción del contenido</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="edit-square" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={parcelData.fields.product_description}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'product_description', 35);
                              dispatch(setParcelDataError({ point: 'product_description', data: false }));
                            }}
                          />
                        </InputGroup>
                        {parcelData.errors.product_description && (
                          <div className={`invalid-tooltip ${parcelData.errors.product_description ? 'd-block' : 'd-none'}`}>
                            {parcelData.errors.product_description}
                          </div>
                        )}
                      </Col>

                      <Col xs="12" sm="8" md="6" lg="12" xxl="8">
                        <Form.Label>Dimensiones</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup className="mb-1">
                          <InputGroup.Text>
                            <CsLineIcons icon="box" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Largo"
                            maxLength={3}
                            value={parcelData.fields.depth}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'depth', 3);
                              dispatch(setParcelDataError({ point: 'depth', data: false }));
                            }}
                          />
                          <InputGroup.Text>
                            <CsLineIcons icon="multiply" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Ancho"
                            maxLength={3}
                            value={parcelData.fields.width}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'width', 3);
                              dispatch(setParcelDataError({ point: 'width', data: false }));
                            }}
                          />
                          <InputGroup.Text>
                            <CsLineIcons icon="multiply" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Alto"
                            maxLength={3}
                            value={parcelData.fields.height}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'height', 3);
                              dispatch(setParcelDataError({ point: 'height', data: false }));
                            }}
                          />
                          {parcelData.errors.height && (
                            <div className={`invalid-tooltip ${parcelData.errors.height ? 'd-block' : 'd-none'}`}>{parcelData.errors.height}</div>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs="auto">
                        <Form.Label>Peso</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup className="mb-1" style={{ maxWidth: '120px' }}>
                          <InputGroup.Text id="input-peso">
                            <CsLineIcons icon="download" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="kg"
                            maxLength={3}
                            value={parcelData.fields.weight}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'weight', 2);
                              dispatch(setParcelDataError({ point: 'weight', data: false }));
                            }}
                          />
                          {parcelData.errors.weight && (
                            <div className={`invalid-tooltip ${parcelData.errors.weight ? 'd-block' : 'd-none'}`}>{parcelData.errors.weight}</div>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs="auto" sm="auto" className="d-flex flex-column bg-infow">
                        <div className="inline-block">
                          <label className="form-label">¿Deseas asegurar?</label>
                          <input
                            type="checkbox"
                            // && 'checked' checar bien esto
                            checked={parcelData.fields.insurance}
                            className="form-check-input cursor-pointer ms-1"
                            name="terms"
                            onClick={(e) => {
                              dispatch(setParcelData({ point: 'insurance', data: e.target.checked }));
                            }}
                          />
                        </div>
                        <div style={{ maxWidth: '210px', position: 'relative' }}>
                          <InputGroup>
                            <InputGroup.Text>
                              <CsLineIcons icon="dollar" size="17" className="text-alternate" />
                            </InputGroup.Text>
                            <Form.Control
                              type="text"
                              maxLength={5}
                              placeholder="Valor del contenido"
                              disabled={!parcelData.fields.insurance}
                              value={parcelData.fields.product_value_shipping}
                              onChange={(e) => {
                                updateField(setParcelData, e.target.value, 'product_value_shipping', 5);
                                dispatch(setParcelDataError({ point: 'product_value_shipping', data: false }));
                              }}
                            />
                          </InputGroup>
                          {parcelData.errors.product_value_shipping && (
                            <div className={`invalid-tooltip ${parcelData.errors.product_value_shipping ? 'd-block' : 'd-none'}`}>
                              {parcelData.errors.product_value_shipping}
                            </div>
                          )}
                        </div>
                      </Col>
                      {multiPiecesOk && (
                        <Col xs="auto" className="me-lg-5 bg-infoe">
                          <Form.Label>Multi-piezas</Form.Label>
                          <InputGroup>
                            <Dropdown className="d-inline-block">
                              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-11">
                                {`${pieces} pieza`}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="shadow" style={{ maxWidth: '88px' }}>
                                <Dropdown.Item onClick={() => setPieces(1)}>1 pieza</Dropdown.Item>
                                <Dropdown.Item onClick={() => setPieces(2)}>2 piezas</Dropdown.Item>
                                <Dropdown.Item onClick={() => setPieces(3)}>3 piezas</Dropdown.Item>
                                <Dropdown.Item onClick={() => setPieces(4)}>4 piezas</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </InputGroup>
                        </Col>
                      )}
                      <Col xs="12" lg="auto" xxl="12" className="position-relative pb-5 pt-3 pb-lg-0 d-lg-flex align-items-end bg-infow">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input me-2 cursor-pointer"
                            name="parcel"
                            checked={saveParcel}
                            onClick={() => {
                              setSaveParcel(!saveParcel);
                            }}
                          />
                          <OverlayTrigger
                            delay={{ show: 500, hide: 0 }}
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">Selecciona si deseas guardar la información del paquete</Tooltip>}
                          >
                            <label className="form-label">Guardar paquete</label>
                          </OverlayTrigger>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              {/* Address End */}
            </Col>
            {/* btn submit */}
            <Col xs="12" className="col-lg order-1 order-lg-0">
              <h2 className="small-title invisible">Servicios</h2>
              <Card className="sh-30 sh-sm-40 sh-lg-60 hover-img-scale-up">
                <img src="/img/autopaquete/enviar/button-services.webp" className="card-img h-100 scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column  bg-transparent">
                  <div>
                    <div className="cta-1 mb-3 text-black w-75 w-md-100">Elige el servicio ideal</div>
                    <div className="w-50 text-black d-none d-sm-block mb-3">Nos adptamos a tus necesidades ofreciendo servicios nacionales y locales.</div>
                  </div>
                  <div>
                    <Button className="btn btn-icon btn-icon-start mt-5" variant="primary" type="submit">
                      <CsLineIcons icon="chevron-right" /> <span>Ver servicios disponibles</span>
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          {/* End  Package */}
        </form>
      </div>
    </>
  );
};

export default ShippingForm;
