import axios from 'axios';

// REACT_APP_API_V1=https://api.autopaquete.com.mx/v1
const DEFAULT_TIMEOUT = process.env.REACT_APP_API_TIMEOUT || 10000;
// POST
const apiV1AxiosInstanceLogin = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
  timeout: DEFAULT_TIMEOUT,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    accept: '*/*',
  },
});
// GET
const msZipCodeAxiosInstance = axios.create({
  baseURL: `https://postalcodes.autopaquete.com.mx/coberturas/detalles-codigo-postal`,
  timeout: DEFAULT_TIMEOUT,
  responseType: 'json',
  headers: {
    Authorization: 'Token 70cb427de4630ca4d8d2447fafb359700539e306',
    'Content-Type': 'application/json',
  },
});
// POST
const checkClabeAxiosInstance = axios.create({
  baseURL: 'https://payments.autopaquete.com.mx/api/check-clabe/',
  timeout: DEFAULT_TIMEOUT,
  responseType: 'json',
});

// GET
const apiV1AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
  timeout: DEFAULT_TIMEOUT,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json', // Enviamos datos en JSON
    accept: '*/*',
    //  Accept: 'application/json', // Solicitamos una respuesta en JSON
  },
});
const apiV1AxiosInstanceForm = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
  timeout: DEFAULT_TIMEOUT,
  responseType: 'json',
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: '*/*',
  },
});
// const getToken = () => {
//   const token = JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key;
//   // const token = '9345ac6c48a5e6dfd7e07af769a15af02c5319ad'; // TOKEN SANDBOX
//   // console.log('token', token);
//   return token;
// };
const getToken = () => {
  try {
    const storedData = localStorage.getItem('persist:starter-project');
    if (!storedData) return null; // Retorna null si no existe
    const authData = JSON.parse(JSON.parse(storedData).auth);
    return authData?.currentUser?.api_key || null; // Retorna null si no hay api_key
  } catch (error) {
    console.error('Error al obtener el token:', error);
    return null; // Maneja errores de parsing o ausencia de datos
  }
};
// Agregar un interceptor de solicitudes para actualizar el token antes de cada solicitud
apiV1AxiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
apiV1AxiosInstanceForm.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Configurar el interceptor de respuesta
// apiV1AxiosInstance.interceptors.response.use(
//   (response) => response, // Respuestas exitosas se pasan sin modificar
//   (error) => {
//     if (error.response) {
//       // Convertir el error en una resolución para manejarlo en `then`
//       return Promise.resolve(error.response);
//     }
//     return Promise.reject(error); // Otros errores
//   }
// );

export { apiV1AxiosInstance, apiV1AxiosInstanceLogin, checkClabeAxiosInstance, msZipCodeAxiosInstance, apiV1AxiosInstanceForm };
