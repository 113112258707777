import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
// api
import { getOverweights } from '../../../../../api/Api-admin';
// components
import Loading from '../../../../../../components/loading/LoadingRed';
import OverweightRow from './components/OverweightRow';
import OverweightCard from './components/OverweightCard';

const LastOverweights = ({ crmId, userName }) => {
  const [overweights, setOverweights] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalOverweight, setTotalOverweight] = useState(0);

  const [error, setError] = useState('');

  const handlerGetOverweights = async () => {
    try {
      setIsLoading(true);
      const response = await getOverweights(crmId, 1, 5);
      // console.log(response);
      if (response.overweights !== undefined) {
        setOverweights(response.overweights);
        setTotalOverweight(response.total_items);
      } else if (response.error.admin) {
        const errorMessage = response.error.admin[0].trim();
        setError(errorMessage);
        if (errorMessage.includes('No se encontraron envíos con los parámetros especificados.')) {
          setError('');
          setOverweights([]);
        }
      }
    } catch {
      setError('Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handlerGetOverweights();
  }, []);
  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {error && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>{error}</h3>
        </Row>
      )}
      {overweights?.length === 0 && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>No hay sobrepesos registrados.</h3>
        </Row>
      )}
      {totalOverweight > 5 && (
        <NavLink to={`/users-all/overweights/${crmId}/${userName}`} className="mb-3 cursor-pointer text-decoration-underline text-end">
          Ver todos
        </NavLink>
      )}

      {overweights?.length > 0 && (
        <>
          <OverweightRow />
          {overweights?.map((overweight, index) => (
            <OverweightCard data={overweight} key={index} />
          ))}
        </>
      )}
    </>
  );
};

export default LastOverweights;
