import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import 'react-toastify/dist/ReactToastify.css';
// utils
import { base64ToFile } from 'utils/base64ToFile';

// components
import Header from './sections/Header';
import Footer from './sections/Footer';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';
import { getResourcesForm, createSupportTicket, createFinaceTicket } from '../../../api/Api-user';
import Loading from '../../../components/loading/Loading';

const SupportForm = ({ children, formTitle, values, setValues, setErrors, validationSchema }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState({
    title: '',
    img: '',
    responseTime: '',
    description: '',
    warning: '',
  });
  const handleResourcesForm = async () => {
    try {
      const response = await getResourcesForm();
      const incidenceList = response.results;
      // console.log('getResourcesForm: ', response);
      const result = incidenceList.find((element) => element.title === formTitle);
      // console.log('recursos del formulario:', formTitle, ':', result);
      setResources({
        title: result.title,
        img: result.incidence_img,
        responseTime: result.description,
        description: result.header,
        warning: result.comment,
      });
    } catch {
      // console.log('getResourcesForm catch error ');
    }
  };

  const handleCreateReport = async () => {
    setIsLoading(true);
    // Covert to file
    const labelFile = base64ToFile(values.label, `${values.shippingId}.pdf`);
    // Update values with new label file
    const updatedValues = {
      ...values,
      label: labelFile,
    };
    // console.log('Create ticket data:', updatedValues);
    try {
      const response = await createSupportTicket(updatedValues);
      // console.log('response support ticket:', response);
      if (response.ticket_info !== undefined) {
        ToastSuccess(
          `¡Gracias por enviar tu reporte de ${values.incidentType}! Hemos generado tu reporte exitosamente con el número ${response.ticket_info.ticket_number}. `
          // Te hemos enviado un correo donde podrás dar seguimiento a tu solicitud. Si tienes alguna
          // pregunta, no dudes en contactarnos.
        );
        history.push('/reportes/historial');
      } else {
        const firstKey = Object.keys(response.data.error)[0];
        ToastErrorApi(response.data.error[firstKey]);
        // ToastErrorApi(response.data.error.tickets[0].incident_type);
        // ToastErrorApi(response.data.error.tickets[0].evidence_9);
      }
    } catch (error) {
      ToastErrorCath();
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  // Validation
  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(values, { abortEarly: false });
      handleCreateReport();
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setErrors(formErrors);
      ToastErrorCath('Por favor, revisa y completa todos los campos obligatorios.');
    }
  };
  useEffect(() => {
    handleResourcesForm();
  }, []);
  // console.log('Support Form:', values);
  return (
    <Form onSubmit={validateForm}>
      {isLoading && <Loading />}
      <Col xs="12" lg="10" className="col-lg mt-5">
        {/* Start */}
        <Card className="mb-5">
          <Card.Body>
            <Header title={resources.title} img={resources.img} description={resources.description} />
            {children}
            <Footer values={values} setValues={setValues} responseTime={resources.responseTime} warning={resources.warning} />
            <div className="d-flex justify-content-center">
              <Button variant="primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
                <CsLineIcons icon="chevron-right" /> <span>Crear Reporte</span>
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Form>
  );
};

export default SupportForm;
