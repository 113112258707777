import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

// api
import { getBillingDocs } from '../../../../../api/Api-admin';

// components
import Loading from '../../../../../../components/loading/LoadingRed';
import BillingRow from '../components/BillingRow';
import BillingCard from '../components/BillingCard';

const LastReceipts = ({ crmId }) => {
  const [receipts, setReceipts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const handlerGetReceipts = () => {
      getBillingDocs('receipts', crmId, 1, 5)
        .then((response) => {
          // console.log('Get receipts res -->', response);
          if (response.data !== undefined) {
            setIsLoading(false);
            setReceipts(response.data);
            setMessage('No hay recibos registrados.');
          }
        })
        .catch(() => {
          setIsLoading(false);
          setReceipts([]);
          setMessage('Error: No se pudieron obtener los últimos recibos. Por favor, verifica tu conexión a Internet e inténtalo nuevamente.');

          // console.log('Err catch get receips',err)
        });
    };
    handlerGetReceipts();
  }, []);
  // console.log('Get receipts res -->', receipts)
  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {receipts?.length === 0 ? (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>{message}</h3>
        </Row>
      ) : (
        <>
          {/* List Header Start */}
          {receipts?.length > 0 && <BillingRow />}
          {/* List Header End */}
          {/* List Items Start */}
          {receipts?.map((receipt, index) => (
            <BillingCard data={receipt} key={index} crmId={crmId} doc="Recibo" />
          ))}
          {/* List Items End */}
        </>
      )}
    </>
  );
};

export default LastReceipts;
