import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// components
import CardStatement from './components/CardStatement';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import LoadingCar from '../../../components/loading/LoadingCar';
import StatementsCSV from '../../../components/reactCsv/StatementsCSV';
import Paginate from '../../../components/Paginate';
import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';

// Api
import { getStatementsHistory } from '../../../api/Api-user';

const Statements = () => {
  const title = 'Movimientos';
  const description = 'Movimientos Plataforma Autopaquete';
  // -Local States
  const [statements, setStatements] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [changePage, setChangePage] = useState(1); // para la peticion
  const [changeItemsNum, setChangeItemsNum] = useState(10); // para la peticion
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  // - Use references to do scroll Up
  const startRef = useRef(null);
  // const { currentUser } = useSelector((state) => state.auth);
  const settingPage = (limit) => {
    setIsLoading(true);
    setChangePage(1);
    setChangeItemsNum(limit);
  };
  const handlerGetStatements = async () => {
    try {
      const response = await getStatementsHistory(changePage, changeItemsNum);
      // console.log('response', response);
      if (response.statement !== undefined) {
        setStatements(response.statement);
        setCurrentPage(response.current_page); // para actualizar la vista
        setPageSize(response.page_size); // para actualizar la vista
        setTotalPages(response.total_pages);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (response.statement.length === 0) {
          setMessage('Aún no hay movimientos registrados en tu historial. Una vez que realices transacciones o envíos, podrás ver el resumen aquí.');
        }
        // if (page > response.total_pages) setCurrentPage(0);
      } else if (response.error) {
        setStatements(statements || []);
        setTotalPages(0);
        // const firstKey = Object.keys(response.error)[0];
        // ToastErrorApi(response.error[firstKey]);
        handleAPIError(response);
      }
    } catch (error) {
      // ToastErrorCath();
      setStatements(statements || []);

      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handlerGetStatements();
  }, [changePage, changeItemsNum]);

  // console.log('Statemens:', statements);
  return (
    <>
      {statements === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </div>

        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row>
            <Col className="mb-1 text-end">
              {/* Excel Buttons  */}
              {statements?.length > 0 && <StatementsCSV statements={statements} crmId="" />}
              {/* Dropdown  */}
              <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-2 ${statements?.length === 0 && 'invisible'}`}>
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                  {`${pageSize} Movimientos`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Movimientos
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={`${statements?.length === 0 && 'invisible'} text-muted my-3 d-lg-flex`}>{`Página ${currentPage}`}</div>
            </Col>
          </Row>

          {/* List Header Start */}
          <Row className="g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort">
            <Col md="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
              <div className="text-muted text-small cursor-pointer">CONCEPTO</div>
            </Col>
            <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">FECHA MOVIMIENTO</div>
            </Col>
            <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointeR">REFERENCIA</div>
            </Col>
            <Col md="3" className="d-flex flex-column pe-1 justify-content-center ps-4">
              <div className="text-muted text-small cursor-pointeR">REFERENCIA ADICIONAL</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">MONTO</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center text-end">
              <div className="text-muted text-small cursor-pointer">SALDO</div>
            </Col>
          </Row>
          {/* List Header End */}
          {/* List Items Start */}
          {statements?.length === 0 && message ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center my-5">{message}</h3>
              <NavLink to="/envios/enviar" className="btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto">
                <CsLineIcons icon="plus" /> <span>Crear envío</span>
              </NavLink>
            </div>
          ) : (
            <>
              {/* List statements Items Start */}
              {statements?.map((statement) => (
                <CardStatement key={statement.id} statement={statement} />
              ))}

              {/* List statements Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Statements;
