import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
// utils
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
// import { cleanData, cleanShippingId } from 'utils/textFormat';

// components
import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';
import RainCalendar from '../../../components/calendars/RainCalendar';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import LoadingCar from '../../../components/loading/LoadingCar';
import CardShipping from './components/CardShipping';
import Paginate from '../../../components/Paginate';
import ShippingCSV from '../../../components/reactCsv/ShippingCSV';
// api
import { getShippingHistory, getFilteredHistory, getAllServices } from '../../../api/Api-user';

const { FormatMoney } = require('format-money-js');

const History = () => {
  const title = 'Historial';
  const description = 'Historial Plataforma Autopaquete';
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // const history = useHistory();

  // -Local States
  const [isLoading, setIsLoading] = useState(false);
  const [shippings, setShippings] = useState(null);
  const [emptyHistory, setEmptyHistory] = useState(null);

  // Start Pagination States
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // solo para la vista
  const [pageSize, setPageSize] = useState(10); // solo para la vista
  const [totalItems, setTotalItems] = useState(0);
  const [changePage, setChangePage] = useState(1); // para la peticion
  const [changeItemsNum, setChangeItemsNum] = useState(10); // para la peticion
  // End Pagination States
  // Start Filter States
  const [filteredMode, setFilteredMode] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // End Filter States
  // Start States for Components for Filtering
  const [identifier, setIdentifier] = useState('');
  const [selectStartDate, setSelectStartDate] = useState({ date: undefined });
  const [selectEndDate, setSelectEndDate] = useState({ date: undefined });
  const [filteredSuccess, setFilteredSuccess] = useState('');
  const [servicesList, setServicesList] = useState([]);
  const fm = new FormatMoney({
    decimals: 0, // No decimales
    separator: ',', // Separador de miles
    decimalPoint: '.', // Punto decimal
    symbol: '', // Sin símbolo, ya que solo necesitas el número
  });
  // End States for Components for Filtering
  const resetInitialDate = () => {
    setSelectEndDate({ date: undefined });
    setSelectStartDate({ date: undefined });
    setStartDate('');
    setEndDate('');
  };

  const filterInitialValues = () => {
    setIdentifier('');
    setServiceName('');
    resetInitialDate();
    setFilteredSuccess(''); // para eliminar el mensaje "Filtro por nombre..."
  };
  // - Get all shippings
  const handlerGetShippingHistory = async () => {
    if (shippings) {
      // solo si shipping es true [] activa este loader, si es falsy null usa el loader del car para los inicios
      setIsLoading(true);
    }
    try {
      const response = await getShippingHistory(changePage, changeItemsNum);
      // console.log('getShippingHistory response:', response);
      if (response.shippings !== undefined) {
        // en cada respuesta actualizar el estado currentPage
        // para asegurarse de que es la pag esperada
        setShippings(response.shippings);
        setTotalPages(response.total_pages); // para manejar la paginacion, si el valor es 1 no se muestra
        setTotalItems(response.total_items);
        setCurrentPage(response.current_page); // para actualizar la vista
        setPageSize(response.page_size); // para actualizar la vista
        if (startRef.current && startRef.current.scrollIntoView) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        setShippings(shippings || []); // si shippings es falsy actualiza con [] para eliminar el loader
        setTotalPages(0);
        setFilteredSuccess(''); // para eliminar el mensaje "Filtro por servicio..."
        const firstKey = Object.keys(response.error)[0];
        const errorMessage = response.error[firstKey][0].trim();
        if (!errorMessage.includes('No se encontraron envíos con los parámetros especificados.')) {
          // solo si es diferente a este error muestralo
          // es para que salga este mensaje cuando el historial esta vacio
          setEmptyHistory(false);
          ToastErrorApi(response.error[firstKey]);
        } else {
          setEmptyHistory(shippings || true); // para mostrar mensaje de "No tienes historial"
        }
      }
    } catch (error) {
      setShippings(shippings || []);
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  // - Search shipping by filters
  const handlerGetFilteredHistory = async () => {
    setEmptyHistory(false); // temporal para hacer pruebas
    if (!endDate && !startDate && !identifier.trim() && !serviceName.trim()) {
      setFilteredMode(false); // si changePage es mayor a 1 lo pone en true el btn buscar pero aqui es error esto es para que la paginacion siga funcionando en modo no filtrando
      ToastErrorCath(`Error: Por favor, ingresa la información necesaria para filtrar.`);
      return;
    }
    try {
      setIsLoading(true);
      const response = await getFilteredHistory(changePage, changeItemsNum, identifier, serviceName, startDate, endDate);
      // console.log(' getFilteredHistory response:', response);
      if (response.shippings !== undefined) {
        setShippings(response.shippings);
        setTotalPages(response.total_pages);
        setCurrentPage(response.current_page); // actualizar la vista de la paginacion
        setTotalItems(response.total_items);
        setPageSize(response.page_size); // para actualizar la vista
        setFilteredSuccess(
          `Resultados del filtro: ${identifier} ${serviceName} ${startDate ? ` del ${moment(startDate).format('DD-MM-YY')}` : ''} ${
            endDate ? `al ${moment(endDate).format('DD-MM-YY')}` : ''
          }`
        );
        if (startRef.current && startRef.current.scrollIntoView) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        setShippings([]);
        // const firstKey = Object.keys(response.error)[0];
        // ToastErrorApi(response.error[firstKey]);
        handleAPIError(response);
        setTotalPages(0);
        setFilteredSuccess(''); // para eliminar el mensaje "Filtro por servicio..."
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setFilteredMode(true); // respuesta exitosa o no es necesario para mantener el modo filtrando y que aparezca el btn regresar
    }
  };
  const settingPage = (record) => {
    // setIsLoading(true); // handlerGetShippingHistory este lo ejecuta y el componente paginate
    // setPageSize(record); se eliminaron estos porque causaban re renderizados
    // setCurrentPage(1);
    setChangeItemsNum(record); // ejecuta el useEffect que decide que funcion ejecutar
    setChangePage(1); // ejecuta el useEffect que decide que funcion ejecutar
  };
  const returnInitialMode = () => {
    // handlerGetFilteredHistory() o  handlerGetShippingHistory() activan el loader
    // setFilterBy('envío'); // ejecuta el useEffect que prepara los estados para el filtrado
    setFilteredMode(false);
    filterInitialValues();
    if (changePage > 1) {
      setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
    } else {
      // si changePage es 1 no ejecuta el cambio de estado ni el useEffect es necesario llamar a la funcion
      handlerGetShippingHistory();
    }
  };
  // - Whenever the pagination status change
  useEffect(() => {
    if (filteredMode) {
      handlerGetFilteredHistory();
    } else {
      handlerGetShippingHistory();
    }
  }, [changePage, changeItemsNum]);

  useEffect(() => {
    getAllServices()
      .then((response) => {
        // console.log('service list res:', response);
        setServicesList(response.list);
      })
      .catch(() => {
        setServicesList(['DHL', 'ESTAFETA', 'JYT', 'ESTAFETA']);
      });
  }, []);
  // console.log(`PERIODO ${startDate} - ${endDate}`);
  // console.log(`Es modo filtrando? ${filteredMode ? 'si' : 'no'}`);
  // console.log(`changePage ${changePage} and changeItemsNum: ${changeItemsNum}`);
  // console.log(`currentPage ${currentPage} and pageSize: ${pageSize}`);

  return (
    <>
      {shippings === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      {/* pageContainer I use it to add opacity screen while loading data */}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Excel Buttons Start */}
            <Col sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-3 order-sm-2">
              {shippings?.length > 0 && <ShippingCSV shippings={shippings} crmId="" />}
            </Col>
            {/* Excel  Buttons End */}
            {/* Top Buttons Start */}
            <Col xs="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-2 order-sm-3">
              <NavLink to="/envios/enviar" className="btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-2 w-100 w-md-auto">
                <CsLineIcons icon="plus" /> <span>Crear envío</span>
              </NavLink>
            </Col>
            {/* Top Buttons End */}
          </Row>
        </div>
        <div style={{ minHeight: '50vh' }} id="cardContainer">
          {/* Elements for Filtering Start */}
          <Row className="g-0 mt-sm-5 d-flex align-items-end bg-infow">
            {/* Filters Start */}
            <Col xs="auto" xl="9" className="bg-warningw">
              <Row className="g-0">
                <Col xs="auto" className="mb-2 me-2">
                  <Form.Control
                    type="text"
                    style={{ fontFamily: 'Montserrat', width: '275px' }}
                    placeholder="ID Envío o Nombre Destinatario"
                    value={identifier}
                    onChange={(e) => {
                      setIdentifier(e.target.value);
                    }}
                  />
                </Col>
                <Col xs="auto" lg="7" className="mb-2 bg-alternatew" style={{ minWidth: '275px' }}>
                  <Dropdown className="d-inline-block">
                    <Dropdown.Toggle variant="foreground-alternate" className="shadow d-flex align-items-center justify-content-between sw-24">
                      {serviceName || 'PAQUETERIA'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow sw-auto" style={{ minHeight: '100px' }}>
                      <Dropdown.Item onClick={() => setServiceName('')}>NINGUNA</Dropdown.Item>
                      {servicesList?.map((service) => (
                        <Dropdown.Item key={service} onClick={() => setServiceName(service)}>
                          {service}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                {/* Calendars Start */}
                <Col xs="12" sm="auto" className="mb-2 mb-xl-0">
                  <Row className="g-0 me-sm-2">
                    <Col xs="6" className="pe-2">
                      <RainCalendar
                        selectDate={selectStartDate}
                        setSelectDate={setSelectStartDate}
                        setState={setStartDate}
                        state={startDate}
                        holder="Fecha Inicial"
                        width="100px"
                      />
                    </Col>
                    <Col xs="6">
                      <RainCalendar selectDate={selectEndDate} setSelectDate={setSelectEndDate} setState={setEndDate} holder="Fecha Final" width="100px" />
                    </Col>
                  </Row>
                </Col>
                {/* Calendars End */}
                <Col xs="12" md="auto">
                  <Row className="g-0">
                    <Col xs="auto" className="pe-2">
                      <Button
                        variant="primary"
                        style={{ height: '36px' }}
                        className="w-100"
                        onClick={() => {
                          if (changePage > 1) {
                            // la primera vez el estado filteredMode es falso
                            setFilteredMode(true); // para que el useEffect funcione bien
                            setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
                          } else {
                            handlerGetFilteredHistory();
                          }
                        }}
                      >
                        <span>Buscar</span>
                      </Button>
                    </Col>
                    {filteredMode && (
                      <Col xs="auto">
                        <Button variant="outline-primary" style={{ height: '36px' }} className="w-100" onClick={returnInitialMode}>
                          <span>Regresar</span>
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            {/* Filters End */}
            {/* Items selector Start */}
            <Col xs="12" xl="3" className="mt-2 mt-lg-0 d-flex justify-content-end align-items-end bg-secondaryw">
              <Row className="g-0">
                <Col>
                  <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${shippings?.length < 2 && 'd-none'}`}>
                    <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-15">
                      {`${pageSize} Envíos`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow dropdown-menu-end">
                      <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                        10 Envíos
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                        20 Envíos
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                        50 Envíos
                      </Dropdown.Item>
                      <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                        100 Envíos
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            </Col>
            {/* Items selector End */}
          </Row>
          {/* Elements for Filtering End */}
          <hr className="bg-muted mb-3 mt-3 mt-xl-4" />
          {/* List Header Start */}
          {filteredSuccess && <div className="text-muted mb-3">{filteredSuccess}</div>}
          <div className={`d-flex justify-content-between mb-3 text-muted  ${shippings?.length < 2 && 'd-none'}`}>
            <span>{`Total de envíos: ${fm.from(totalItems)}`}</span>
            <span>{`Página ${currentPage} `}</span>
          </div>
          <Row className={`g-0 mb-2 d-none d-lg-flex ${shippings?.length === 0 && 'invisible'} `}>
            <Col xs="auto" className="sw-11 d-none d-lg-flex" />
            <Col>
              <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-4 h-100">
                <Col xs="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVÍO</div>
                </Col>
                <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
                </Col>
                <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                  <div className="text-muted text-small cursor-pointer">COSTO</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                  <div className="text-muted text-small cursor-pointer">PESO COBRADO</div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* List Header End */}
          {/* List shipment Items End */}
          {shippings?.length === 0 ? (
            <div className="py-5 mt-lg-5 d-flex flex-column justify-content-center align-items-center w-70 m-auto">
              {emptyHistory && <h3 className="text-center mt-5">Tu historial de envíos estará disponible una vez que realices tu primer envío.</h3>}
              <img alt="empty" src="/img/animation/userNotFound.webp" style={{ width: '150px', height: '150px' }} />
            </div>
          ) : (
            <>
              {/* List shipment Items Start */}
              {shippings?.map((shipping) => (
                <CardShipping key={shipping.shipping_label_id} shipping={shipping} />
              ))}
              {/* List shipment Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
                {/* se elimino el prop setIsLoaging */}
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default History;
// onClick={(e) => nextPage(e.currentTarget)}
