import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Application, FileSelector } from 'react-rainbow-components';

// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from 'react-spinners/ClipLoader';
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';
// utils
// api
import { createOverweightOrder } from '../../../api/Api-admin';

// const theme = {
//   rainbow: {
//     palette: {
//       brand: '#850000',
//     },
//   },
// };
const OrderModal = ({ isModalOpen, setIsModalOpen, setRender, render }) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const handlerCreateOrder = async () => {
    try {
      // console.log('Valor de file:', file);
      // console.log('Valor de file[0]:', file?.[0]);
      // Verificar si el archivo existe antes de proceder
      if (!file || !file[0]) {
        ToastErrorCath('Error: No se detectó un archivo válido. Por favor, selecciona un archivo Excel antes de continuar.');
        return; // Terminar la ejecución si no hay archivo
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append('excel_file', file[0]);
      const response = await createOverweightOrder(formData);
      // console.log('create order response:', response);
      if (response.success) {
        setFile(null);
        setRender(!render);
        ToastSuccess('El archivo Excel se ha procesado exitosamente.');
      }
      // revisar como regresa el error
      // if (response.data.error) {
      //   handleAPIError(response.data);
      // }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Crear orden de sobrepesos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Application theme={theme}></Application> */}
        <FileSelector
          className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto mx-auto"
          // style={containerStyles}
          label="Subir archivo excel"
          placeholder="Sube o arrastra tu archivo aquí"
          bottomHelpText="Selecciona solo un archivo"
          onChange={setFile}
          borderRadius="semi-rounded"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => setIsModalOpen(false)}>
          Cancelar
        </Button>
        {/* <Button size="lg" type="submit" disabled={isLoading}>
              {isLoading && <ClipLoader color="#FFFFFF" loading={isLoading} size={10} />}
              {isLoading ? ' Cargando' : ' Iniciar sesión'}
            </Button> */}
        <Button variant="primary" onClick={handlerCreateOrder}>
          {isLoading && <ClipLoader color="#FFFFFF" loading={isLoading} size={10} />}
          {isLoading ? ' Cargando' : 'Continuar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default OrderModal;
