import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import * as Yup from 'yup';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// components
// import Loading from '../../../components/loading/LoadingRed';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import SupplierCard from '../shipping/components/SupplierCard';
import { ToastErrorCath } from '../../../components/alerts/toast';
// api
import { getZipCode, getRates } from '../../../api/Api-user';

const Quote = () => {
  const title = 'Cotizar';
  const description = 'Cotizar Plataforma Autopaquete';
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // - Local States
  const [rates, setRates] = useState(undefined);
  const [isVolumetricWeightOk, setIsVolumetricWeightOk] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [totalWeight, setTotalWeight] = useState(null);
  const [dataRates, setDataRates] = useState({
    zipFrom: '',
    zipTo: '',
    stateFrom: { name: '', city: '' },
    stateTo: { name: '', city: '' },
    productValueShipping: '',
    checkBoxSecure: '',
    height: '',
    width: '',
    depth: '',
    weight: '',
    productValue: '',
  });

  const [dataRatesError, setDataRatesError] = useState({
    zipFrom: '',
    zipTo: '',
    parcel: '',
    insurance: '',
    height: '',
    width: '',
    depth: '',
    weight: '',
    productValueShipping: '',
    checkBoxSecure: '',
  });
  // Schema Validation
  const validationParcelSchema = Yup.object().shape({
    zipFrom: Yup.string().min(5, 'Este campo debe tener 5 dígitos').required('El código postal es obligatorio.'),
    zipTo: Yup.string().min(5, 'Este campo debe tener 5 dígitos').required('El código postal es obligatorio.'),
    height: Yup.string().required('La altura del paquete es obligatoria.'),
    depth: Yup.string().required('La profundidad del paquete es obligatoria.'),
    weight: Yup.string().required('El peso del paquete es obligatorio.'),
    width: Yup.string().required('El ancho del paquete es obligatorio.'),
  });
  // - Data Validation and Updating
  const updateData = (type, data, validation) => {
    // setDataRatesError({ ...dataRatesError, [type]: '' });
    if (data === '' || validation.test(data)) setDataRates({ ...dataRates, [type]: data });
  };

  // - Get Rates
  const handlerQuoter = async () => {
    // console.log('cotizar con:', dataRates);
    try {
      if (!dataRates.stateFrom.name || !dataRates.stateTo.name) {
        ToastErrorCath(
          'Error: Por favor, verifica que los códigos postales sean válidos. Asegúrate de que al introducir el código postal se obtengan correctamente la ciudad y el estado. '
        );
        return;
      }
      // setRates(undefined);
      setIsLoading(true);
      const response = await getRates(dataRates);
      // console.log('response cotizador:', response);

      if (response.zip_from !== undefined) {
        setRates(response.rates);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationParcelSchema.validate(dataRates, { abortEarly: false });
      handlerQuoter();
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setDataRatesError(formErrors);
      // console.log('errores en from cotizar:', formErrors);
      if (formErrors.depth || formErrors.width || formErrors.height) {
        setDataRatesError((prev) => ({
          ...prev,
          parcel: 'Alto, largo y ancho son requeridos',
        }));
      }
      // formAddressFromRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
  };
  useEffect(() => {
    setDataRatesError({ ...dataRatesError, zipFrom: '' });
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (dataRates.zipFrom.length === 5) {
      getZipCode(dataRates.zipFrom)
        .then((response) => {
          // response [{}]ok, []invalid, undefined (no connection)
          if (response) {
            if (response?.length > 0) {
              setDataRates({ ...dataRates, stateFrom: { name: response[0].state.name, city: response[0].city } });
            } else {
              setDataRatesError({ ...dataRatesError, zipFrom: 'Código invalido' });
            }
          } else {
            ToastErrorCath();
            setDataRatesError({ ...dataRatesError, zipFrom: 'Error conexión' });
          }
        })
        .catch(() => {
          setDataRatesError({ ...dataRatesError, zipFrom: 'Sin conexión a Internet' });
        });
    } else {
      // when user is typing
      setDataRatesError({ ...dataRatesError, zipFrom: '' });
      setDataRates({ ...dataRates, stateFrom: { name: '', city: '' } });
    }
  }, [dataRates.zipFrom]);
  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (dataRates.zipTo.length === 5) {
      getZipCode(dataRates.zipTo)
        .then((response) => {
          if (response?.length > 0) {
            // dispatch(setDestinationData({ data: response[0].city, point: 'city' }));
            // dispatch(setDestinationData({ data: response[0].state, point: 'state' }));
            // dispatch(setDestinationDataError({ data: '', point: 'zipTo' }));
            setDataRatesError({ ...dataRatesError, zipTo: '' });
            setDataRates({ ...dataRates, stateTo: { name: response[0].state.name, city: response[0].city } });
          } else {
            setDataRatesError({ ...dataRatesError, zipTo: 'Código invalido' });
          }
        })
        .catch(() => {
          setDataRatesError({ ...dataRatesError, zipTo: 'Sin conexión a Internet' });
        });
    } else {
      // when user is typing
      setDataRatesError({ ...dataRatesError, zipTo: '' });
      setDataRates({ ...dataRates, stateTo: { name: '', city: '' } });
    }
  }, [dataRates.zipTo]);
  useEffect(() => {
    // useEffect check changes on depth, width and height to calculate volumetric weight
    if (dataRates.depth && dataRates.width && dataRates.height) {
      setIsVolumetricWeightOk(Math.ceil((dataRates.height * dataRates.width * dataRates.depth) / 5000));
    } else {
      setIsVolumetricWeightOk('');
    }
  }, [dataRates.depth, dataRates.width, dataRates.height]);
  useEffect(() => {
    if (!dataRates.checkBoxSecure) {
      setDataRates({ ...dataRates, productValue: '' });
      setDataRatesError({ ...dataRatesError, insurance: false });
    } else {
      setDataRatesError({ ...dataRatesError, insurance: true });
    }
  }, [dataRates.checkBoxSecure, dataRates.productValueShipping]);

  // useEffect calculates the total weight
  useEffect(() => {
    if (isVolumetricWeightOk && dataRates.weight) {
      setTotalWeight(Math.max(isVolumetricWeightOk, dataRates.weight));
    } else {
      setTotalWeight(null);
    }
  }, [dataRates.weight, isVolumetricWeightOk]);
  // console.log('errores:', dataRatesError);
  return (
    <>
      {isLoading && <LoadingPulse />}
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Inicio</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End */}
      <Row id="cardContainer">
        <Col xs="12" xl="12">
          <h2 className="small-title">Detalles del envío</h2>
          <Card className="mb-4 position-relative">
            <Card.Body>
              {/* {isLoading && (
                <div
                  className="position-absolute rounded top-0 start-0 d-flex justify-content-center align-items-center"
                  style={{ height: ' 100%', width: '100%', backgroundColor: '#ffffff86' }}
                >
                  <Loading />
                </div>
              )} */}
              <Form autoComplete="off" autoFill="off">
                <Row className="g-3">
                  <Col xs="12" sm="6" lg="3" className="position-relative">
                    <Form.Label>Código postal</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1">
                      <InputGroup.Text id="input-origen">
                        <CsLineIcons icon="pin" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Origen"
                        autoComplete="off"
                        autoFill="off"
                        maxLength={5}
                        value={dataRates.zipFrom}
                        onChange={(e) => {
                          updateData('zipFrom', e.target.value, /^[0-9]+$/i);
                          setDataRatesError({ ...dataRatesError, zipFrom: '' });
                        }}
                      />
                    </InputGroup>
                    <small className="form-text text-primary">{dataRates.stateFrom.city && `${dataRates.stateFrom.city}, ${dataRates.stateFrom.name}.`}</small>
                    {dataRatesError.zipFrom && (
                      <div className={`invalid-tooltip ${dataRatesError.zipFrom ? 'd-block' : 'd-none'}`}>{dataRatesError.zipFrom}</div>
                    )}
                  </Col>
                  <Col xs="12" sm="6" lg="3" className="position-relative">
                    <Form.Label>Código postal</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1">
                      <InputGroup.Text>
                        <CsLineIcons icon="arrow-right" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Destino"
                        autoComplete="off"
                        autofill="off"
                        maxLength={5}
                        value={dataRates.zipTo}
                        onChange={(e) => {
                          updateData('zipTo', e.target.value, /^[0-9]+$/i);
                          setDataRatesError({ ...dataRatesError, zipTo: '' });
                        }}
                      />
                    </InputGroup>
                    <small className="form-text text-primary">{dataRates.stateTo.city && `${dataRates.stateTo.city}, ${dataRates.stateTo.name}.`}</small>
                    {dataRatesError.zipTo && <div className={`invalid-tooltip ${dataRatesError.zipTo ? 'd-block' : 'd-none'}`}>{dataRatesError.zipTo}</div>}
                  </Col>
                  <Col xs="12" sm="8" lg="6" xl="4">
                    <Form.Label>Dimensiones</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1">
                      <InputGroup.Text>
                        <CsLineIcons icon="box" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Largo"
                        maxLength={3}
                        value={dataRates.depth}
                        onChange={(e) => {
                          updateData('depth', e.target.value, /^[0-9]*$/);
                          setDataRatesError({ ...dataRatesError, parcel: '' });
                        }}
                      />
                      <InputGroup.Text>
                        <CsLineIcons icon="multiply" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Ancho"
                        maxLength={3}
                        value={dataRates.width}
                        onChange={(e) => {
                          updateData('width', e.target.value, /^[0-9]*$/);
                          setDataRatesError({ ...dataRatesError, parcel: '' });
                        }}
                      />
                      <InputGroup.Text>
                        <CsLineIcons icon="multiply" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Alto"
                        maxLength={3}
                        value={dataRates.height}
                        onChange={(e) => {
                          updateData('height', e.target.value, /^[0-9]*$/);
                          setDataRatesError({ ...dataRatesError, parcel: '' });
                        }}
                      />
                      {dataRatesError.parcel && (
                        <div className={`invalid-tooltip ${dataRatesError.parcel ? 'd-block' : 'd-none'}`}>{dataRatesError.parcel}</div>
                      )}
                    </InputGroup>
                    <small className="form-text text-primary">{isVolumetricWeightOk && `Peso volumétrico: ${isVolumetricWeightOk} kg.`}</small>
                  </Col>
                  {/*  */}
                  <Col xs="auto" lg="auto">
                    <Form.Label>Peso</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1" style={{ maxWidth: '120px' }}>
                      <InputGroup.Text id="input-peso">
                        <CsLineIcons icon="download" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="kg"
                        maxLength={3}
                        value={dataRates.weight}
                        onChange={(e) => {
                          updateData('weight', e.target.value, /^[0-9]*$/);
                          setDataRatesError({ ...dataRatesError, weight: '' });
                        }}
                      />
                      {dataRatesError.weight && (
                        <div className={`invalid-tooltip ${dataRatesError.weight ? 'd-block' : 'd-none'}`}>{dataRatesError.weight}</div>
                      )}
                    </InputGroup>
                    <small className="form-text text-primary">{totalWeight && `Peso a cobrar: ${totalWeight} kg.`}</small>
                  </Col>
                  <Col xs="auto">
                    <div className="inline-block">
                      <label className="form-label">¿Deseas asegurar?</label>
                      <input
                        type="checkbox"
                        checked={dataRates.checkBoxSecure && 'checked'}
                        className="ms-2 form-check-input "
                        onClick={(e) => {
                          setDataRates({ ...dataRates, checkBoxSecure: e.target.checked });
                        }}
                      />
                    </div>
                    <div style={{ maxWidth: '210px', position: 'relative' }}>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="dollar" size="17" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          maxLength={5}
                          placeholder="Valor del contenido"
                          disabled={!dataRates.checkBoxSecure}
                          value={dataRates.productValue}
                          style={{ maxWidth: '250px' }}
                          onChange={(e) => {
                            updateData('productValue', e.target.value, /^[0-9]*$/);
                            setDataRatesError({ ...dataRatesError, insurance: '' });
                          }}
                        />
                      </InputGroup>
                      {dataRatesError.insurance && (
                        <div className={`invalid-tooltip ${dataRatesError.insurance ? 'd-block' : 'd-none'}`}>Ingrese valor del contenido</div>
                      )}
                    </div>

                    {/* <div className="d-flex flex-column">
                      <div>
                        <label className="form-label">¿Desea asegurar?</label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          checked={dataRates.checkBoxSecure && 'checked'}
                          className="ms-2 form-check-input "
                          onClick={(e) => {
                            setDataRates({ ...dataRates, checkBoxSecure: e.target.checked });
                          }}
                        />
                      </div>
                    </div> */}
                  </Col>
                  {/* <Col xs="auto" sm="4" md="auto" className="position-relative">
                    <Form.Label>Valor del contenido</Form.Label>
                    <InputGroup className="mb-1">
                      <InputGroup.Text id="input-valor">
                        <CsLineIcons icon="dollar" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="MXN"
                        aria-label="Valor del contenido"
                        aria-describedby="input-valor"
                        disabled={!dataRates.checkBoxSecure}
                        type="text"
                        value={dataRates.productValue}
                        style={{ maxWidth: '250px' }}
                        onChange={(e) => {
                          updateData('productValue', e.target.value, /^[0-9]*$/);
                          setDataRatesError({ ...dataRatesError, insurance: '' });
                        }}
                      />
                    </InputGroup>
                    {dataRatesError.insurance && (
                      <div className={`invalid-tooltip ${dataRatesError.insurance ? 'd-block' : 'd-none'}`}>Ingrese valor del contenido</div>
                    )}
                  </Col> */}
                  <Col xs="12" sm="auto" className="d-flex align-items-end bg-infow">
                    <InputGroup>
                      <Button variant="primary" className="btn btn-icon btn-icon-end w-100" onClick={validateForm} style={{ minWidth: '200px' }}>
                        Cotizar
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <div ref={startRef} className="invisible" />
        {rates !== undefined && (
          <Col xs="12" lg="12">
            {/* Items Start */}
            <h2 className="small-title">Servicios disponibles</h2>
            <div className="mb-5">
              {rates?.map((rate) => (
                <SupplierCard key={rate.shipping_code} rate={rate} page="quote" />
              ))}
            </div>
            {/* Items End */}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Quote;
