import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Application, DatePicker } from 'react-rainbow-components';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import moment from 'moment';
import { setLabeIdForPickup } from 'slices/shipping/shippingDataSlice';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
import { cleanData, cleanShippingId } from '../../../../utils/textFormat';
// components
import AutoFill from '../../../../components/form-elements/AutoFill';
import InputText from '../../../../components/form-elements/InputText';
import InputTextFetchData from '../../../../components/form-elements/InputTextFetchData';
import Loading from '../../../../components/loading/Loading';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';

// api
import { createPickup, getZipCode, getShippingDetails } from '../../../../api/Api-user';

// el email puedes ni siquiera enviarlo no se necesita ya
// en pickup zoho detecta la cuenta y manda un email al que ya esta registrado en zoho
// con tickets de soporte si toma en cuenta el email
// el campo streetnumber acepta caracteres especiales(segun daba error)
// no se si los demas campos den error con caracteres especiales pero ya no importa x que ahora se limpia la data

// Se debe limpiar la data con cleanData() cuando se auto rellena el form y cuando el user escribe

const theme = {
  rainbow: {
    palette: {
      brand: '#850000',
    },
  },
};

const NewPickup = () => {
  const title = 'Nueva recolección';
  const description = 'Recolección Plataforma Autopaquete';
  const dispatch = useDispatch();
  const history = useHistory();
  // - Global State from Store.js form Values
  const { labelIdForPickup } = useSelector((state) => state.shipping);
  // - Local States
  const [selectDate, setSelectDate] = useState({ date: new Date() });
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [eveningSchedule, setEveningSchedule] = useState(false);
  const [morningSchedule, setMorningSchedule] = useState(false);
  const initialPickupState = {
    shipping_code: '',
    company: '',
    name: '',
    phone: '',
    street: '',
    street_number: '',
    email: '',
    neighborhood: '',
    zip_code: '',
    reference: '',
    state: '',
    rfc: '',
    city: '',
    shipping_id: '',
    total_packages: '',
    total_weight: '',
    pickup_time: '',
    // pickup_date: moment(selectDate.date).format().slice(0, 10),
    pickup_date: moment(selectDate.date).format('YYYY-MM-DD'),
    // pickup_date: '',
    side_streets: '',
  };
  const initialPickupErrState = {
    shipping_code: '',
    company: '',
    name: '',
    phone: '',
    street: '',
    street_number: '',
    neighborhood: '',
    zip_code: '',
    reference: '',
    state: '',
    rfc: '',
    city: '',
    shipping_id: '',
    total_packages: '',
    total_weight: '',
    pickup_time: '',
    pickup_date: '',
    side_streets: '',
  };
  const [pickup, setPickup] = useState(initialPickupState);
  const [pickupErr, setPickupErr] = useState(initialPickupErrState);
  const [isGettingData, setIsGettingData] = useState(false);
  const [isRequestError, setIsRequestError] = useState(false);
  const [requestError, setRequestError] = useState('');
  const [deleteCount, setDeleteCount] = useState(0);
  // Schema Validation
  const validationSchema = Yup.object().shape({
    shipping_id: Yup.string().required('Por favor, ingresa el ID de envío.'),
    name: Yup.string().min(3, 'El nombre debe tener al menos 3 caracteres.').required('El campo de nombre es obligatorio.'),
    zip_code: Yup.string().min(5, 'El código postal debe tener exactamente 5 caracteres.').required('Por favor, ingresa tu código postal.'),
    street: Yup.string().required('Por favor, ingresa el nombre de la calle.'),
    street_number: Yup.string().required('Por favor, ingresa el número de la calle.'),
    neighborhood: Yup.string().required('Por favor, ingresa tu colonia o barrio.'),
    reference: Yup.string().required('Por favor, proporciona una referencia.'),
    phone: Yup.string().min(10, 'El número de teléfono debe tener al menos 10 dígitos.').required('Por favor, ingresa tu número de teléfono.'),
    total_packages: Yup.string().required('Por favor, ingresa la cantidad total de paquetes.'),
    total_weight: Yup.string().required('Por favor, ingresa el peso total de los paquetes.'),
    side_streets: Yup.string().required('Por favor, ingresa las calles laterales.'),
    pickup_time: Yup.string().required('Selecciona una horario para la recolección.'),
    pickup_date: Yup.string().required('Selecciona una fecha para la recolección.'),
  });
  // update calendar and pickup.pickup_date value
  const handlePickupDateUpdate = (year, month, today) => {
    setSelectDate({ date: new Date(year, month, today) });
    setPickup((prevValues) => {
      return { ...prevValues, pickup_date: moment(new Date(year, month, today)).format('YYYY-MM-DD') };
    });
  };
  // manipulate date
  const calculatePickupDate = () => {
    // Obtén la fecha actual 2024, 9, 18
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    let today = date.getDate();
    let nextDay = today + 1;
    // Obtener día de la semana y hora actual para hacer cálculos
    const dayOfWeek = date.getDay(); // 0: Domingo, 6: Sábado
    const currentTime = date.getHours(); // Hora actual en formato de 24 horas
    // const currentTime = 15;
    const maxPickupTime = 13; // Si la hora actual excede las 1 PM, mueve la fecha de recolección
    // Lógica para ajustar las fechas según el día de la semana y la hora
    if (dayOfWeek === 1 || dayOfWeek === 2 || dayOfWeek === 3) {
      // console.log('es lunes, martes o miercoles');
      if (currentTime >= maxPickupTime) {
        today += 1;
        nextDay += 1;
        handlePickupDateUpdate(year, month, today);
      }
    } else if (dayOfWeek === 4) {
      // console.log('es jueves');
      if (currentTime >= maxPickupTime) {
        today += 1; // Recolección solo el viernes
        nextDay = today;
        handlePickupDateUpdate(year, month, today);
      }
    } else if (dayOfWeek === 5) {
      // Viernes
      nextDay = today;
      // console.log('es viernes:', dayOfWeek);
      handlePickupDateUpdate(year, month, today);
      if (currentTime >= maxPickupTime) {
        today += 3; // Recolección el lunes
        nextDay += 4;
        handlePickupDateUpdate(year, month, today);
      }
    } else if (dayOfWeek === 6) {
      // Sábado
      today += 2; // Mover la fecha de recolección al lunes
      nextDay += 2;
      handlePickupDateUpdate(year, month, today);
    } else if (dayOfWeek === 0) {
      // Domingo
      today += 1; // Mover la fecha de recolección al lunes
      nextDay += 1;
      handlePickupDateUpdate(year, month, today);
    }

    setMinDate(new Date(year, month, today));
    setMaxDate(new Date(year, month, nextDay));
  };
  // - Reset form
  const cleanForm = () => {
    setMorningSchedule(false);
    setEveningSchedule(false);
    setPickup(initialPickupState);
    setPickupErr(initialPickupErrState);
  };
  // - Data Validation and Updating States
  const updateField = (data, validation, field) => {
    // Remove spaces and special characters
    let cleanedData = cleanData(data);
    // if field is "shipping_id" use this cleanShippingId
    if (field === 'shipping_id') {
      cleanedData = cleanShippingId(data);
    }
    if (cleanedData === '' || validation.test(cleanedData)) {
      setPickup({
        ...pickup,
        [field]: cleanedData,
      });
    }
  };
  // - cleanError
  const cleanError = (value, field) => {
    setPickupErr({ ...pickupErr, [field]: value });
  };
  //  To handle api errors
  const handleRequestError = (errorMessage) => {
    setIsRequestError(true);
    setRequestError(errorMessage);
  };
  //  To reset handle api errors
  const cleanRequestError = () => {
    setIsRequestError(false);
    setRequestError('');
  };
  const resetFormValues = () => {
    // console.log('Resetting form values...');
    setMorningSchedule(false);
    setEveningSchedule(false);
    setPickup((prevPickup) => {
      const resetValues = Object.keys(pickup).reduce((acc, key) => {
        // Maintain shippingId and date value
        if (key === 'shipping_id') {
          acc[key] = prevPickup[key];
        } else if (key === 'pickup_date') {
          acc[key] = prevPickup[key]; // Mantener el valor actual de pickup_date;
        } else {
          acc[key] = ''; // Reset the other fields
        }
        return acc;
      }, {});
      // Return the object with the properties reset
      return resetValues;
    });
  };
  // - Form autofill
  const getShippingData = async (input) => {
    setIsGettingData(true);
    let inputValue;
    // if is an event, get value from e.target.value
    if (input && input.target) {
      inputValue = input.target.value;
    } else {
      // if is a direct value (like state value), use it
      inputValue = input || '';
    }
    const key = input?.nativeEvent?.inputType || ''; // Handle the event type if it is an input event
    const cleanedInput = cleanShippingId(inputValue); // Use cleanData to clean data
    // If the user is deleting
    if (key === 'deleteContentBackward' || key === 'deleteContentForward') {
      setDeleteCount((prevCount) => prevCount + 1);
      if (deleteCount >= 5) {
        // remove all functionality if the user deletes more than 2
        //  characters to avoid massive 406 errors
        setIsGettingData(false);
        cleanRequestError();
        return;
      }
    } else {
      // Reset the counter if the user is typing
      setDeleteCount(0);
    }
    try {
      if (inputValue.length > 5) {
        // Retrasar la solicitud por 1 segundo
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const response = await getShippingDetails(cleanedInput);
        // console.log('getShippingDetails response', response);
        if (response.shipping_code !== undefined) {
          // clean up previous errors Start
          setPickupErr(initialPickupErrState);
          cleanRequestError();
          // Set states with response data
          setPickup((prevValues) => ({
            ...prevValues,
            shipping_id: cleanedInput,
            shipping_code: response.shipping_code,
            company: cleanData(response.address_from.company),
            name: cleanData(response.address_from.name),
            phone: cleanData(response.address_from.phone),
            street: cleanData(response.address_from.street),
            street_number: cleanData(response.address_from.street_number), // '-' acept caracteres especiales
            email: response.address_from.email ?? 'default2@gmail.com',
            neighborhood: cleanData(response.address_from.neighborhood),
            zip_code: cleanData(response.address_from.zip_code),
            reference: cleanData(response.address_from.reference),
            state: cleanData(response.address_from.state),
            rfc: response.address_from.rfc || 'XAXX010101000',
            city: cleanData(response.address_from.city),
          }));
        } else if (response.error) {
          handleRequestError(response.error.shippings[0]);
          // resetFormValues(cleanedInput);
          resetFormValues();
          setPickupErr(initialPickupErrState);
          setMorningSchedule(false);
          setEveningSchedule(false);
        }
      } else {
        cleanRequestError();
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsGettingData(false);
    }
  };
  const convertObjectToUppercase = (obj) => {
    const uppercasedObj = {};
    Object.keys(obj).forEach((key) => {
      if (key === 'email' || key === 'shipping_id') {
        // No convertir el campo email y shipping_id a mayúsculas
        uppercasedObj[key] = obj[key];
      } else if (typeof obj[key] === 'string') {
        // Convertir a mayúsculas solo si es string y no es email
        uppercasedObj[key] = obj[key].toUpperCase();
      } else {
        // Mantener el valor si no es string
        uppercasedObj[key] = obj[key];
      }
    });

    return uppercasedObj;
  };
  // - Request handler
  const handlerCreatePickup = async () => {
    // setIsLoading(true);
    const uppercasedPickup = convertObjectToUppercase(pickup);
    // console.log('crear con:', uppercasedPickup);
    try {
      const response = await createPickup(uppercasedPickup);
      // console.log('response pickup:', response);
      if (response.pickup_info !== undefined) {
        ToastSuccess(
          `Tu recolección ha sido programada exitosamente para el dia ${response.pickup_info.pickup_date}. Recolección Id: ${response.pickup_info.pickup_id}`
        );
        history.push('/envios/recolectar');
      } else if (response.error) {
        handleAPIError(response);
      }
      //  else {
      //   ToastErrorCath(
      //     'Error: Lo sentimos, ocurrió un error inesperado al procesar tu solicitud. Por favor, intenta nuevamente más tarde o contacta a nuestro equipo de soporte si el problema persiste.'
      //   );
      // }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  // Validation
  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(pickup, { abortEarly: false });
      handlerCreatePickup();
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setPickupErr(formErrors);
      ToastErrorCath('Por favor, completa todos los campos.');
    }
  };
  // useEffect autofill when user come from documentation page
  useEffect(() => {
    if (labelIdForPickup) {
      getShippingData(labelIdForPickup);
    }
    return () => {
      // Código a ejecutar al salir de la página
      dispatch(setLabeIdForPickup(''));
    };
  }, []);
  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (pickup.zip_code.length === 5) {
      getZipCode(pickup.zip_code).then((res) => {
        // console.log('response:', res);
        if (res.length > 0) {
          setPickup({ ...pickup, city: res[0].city, state: res[0].state.name });
          setPickupErr({ ...pickupErr, city: '', state: '' });
        } else {
          setPickupErr({ ...pickupErr, zip_code: 'Código postal invalido' });
        }
      });
    } else {
      setPickupErr({ ...pickupErr, zip_code: '' });
      setPickup({ ...pickup, city: '', state: '' });
    }
  }, [pickup.zip_code]);
  useEffect(() => {
    if (pickup.shipping_id?.length === 0) {
      cleanForm();
    }
  }, [pickup.shipping_id]);
  useEffect(() => {
    calculatePickupDate();
  }, []);
  useEffect(() => {
    // console.log('dt:', pickup);
  }, [pickup]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <Col xs="12" lg="10">
          <Row className="d-flex flex-column flex-sm-row mb-5">
            {/* Title Start */}
            <Col>
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/envios/recolectar">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </Col>
      </div>
      {/* Title End */}
      <Form onSubmit={validateForm}>
        {isLoading && <Loading />}
        {/* Top Buttons Start */}
        <Col xs="12" lg="10" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0">
          <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
            <CsLineIcons icon="save" /> <span>Programar Recolección</span>
          </Button>
        </Col>
        {/* Top Buttons End */}
        <Col xs="12" lg="10" className="col-lg mt-5">
          {/* Start */}
          <Card className="mb-5">
            <Card.Body>
              <h2 className="small-title">Datos de la guía</h2>
              <Row className="g-3 mb-5">
                <Col md="6" className="position-relative">
                  <InputTextFetchData
                    label="ID Envío"
                    icon="shipping"
                    maxLength=""
                    placeholder="Pega el Id de tu envío"
                    // helpText="oooo"
                    value={pickup.shipping_id}
                    isRequired="true"
                    isError={isRequestError}
                    error={requestError}
                    setValues={getShippingData}
                    isLoading={isGettingData}
                    fieldName="shipping_id"
                    // validation={/^[A-Z0-9 ]+$/i}
                    validation={/^[a-zA-Z0-9]+$/}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.shipping_id && <div className={`invalid-tooltip ${pickupErr.shipping_id ? 'd-block' : 'd-none'}`}>{pickupErr.shipping_id}</div>}
                </Col>
                <Col md="6">
                  <AutoFill label="Paqueteria" value={pickup.shipping_code} readOnly />
                </Col>
                <div>Por temporada alta, te aconsejamos solicitar tu recolección para el mismo día antes de las 10 AM.</div>
              </Row>
              <h2 className="small-title">Dirección de recolección</h2>
              <Row className="g-3 mb-5">
                <Col sm="9" className="position-relative">
                  <InputText
                    label="Nombre del contacto"
                    icon="user"
                    maxLength="30"
                    placeholder=""
                    value={pickup.name}
                    isRequired="true"
                    helpText=""
                    fieldName="name"
                    validation={/^[A-Z0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.name && <div className={`invalid-tooltip ${pickupErr.name ? 'd-block' : 'd-none'}`}>{pickupErr.name}</div>}
                </Col>
                <Col sm="3" className="position-relative">
                  <InputText
                    label="Código Postal"
                    icon="pin"
                    maxLength="5"
                    placeholder=""
                    value={pickup.zip_code}
                    isRequired="true"
                    helpText=""
                    fieldName="zip_code"
                    validation={/^[0-9]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.zip_code && <div className={`invalid-tooltip ${pickupErr.zip_code ? 'd-block' : 'd-none'}`}>{pickupErr.zip_code}</div>}
                </Col>
                <Col sm="9" className="position-relative">
                  <InputText
                    label="Nombre de la calle"
                    icon="home"
                    maxLength="50"
                    placeholder=""
                    value={pickup.street}
                    isRequired="true"
                    helpText=""
                    fieldName="street"
                    validation={/^[A-Z0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.street && <div className={`invalid-tooltip ${pickupErr.street ? 'd-block' : 'd-none'}`}>{pickupErr.street}</div>}
                </Col>
                <Col sm="3" className="position-relative">
                  <InputText
                    label="Número"
                    icon="pin"
                    maxLength="10"
                    placeholder=""
                    value={pickup.street_number}
                    isRequired="true"
                    helpText=""
                    fieldName="street_number"
                    validation={/^[A-Z0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.street_number && (
                    <div className={`invalid-tooltip ${pickupErr.street_number ? 'd-block' : 'd-none'}`}>{pickupErr.street_number}</div>
                  )}
                </Col>
                <Col md="6" className="position-relative">
                  <InputText
                    label="Colonia"
                    icon="home"
                    maxLength="35"
                    placeholder=""
                    value={pickup.neighborhood}
                    isRequired="true"
                    helpText=""
                    fieldName="neighborhood"
                    validation={/^[A-Z0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.neighborhood && <div className={`invalid-tooltip ${pickupErr.neighborhood ? 'd-block' : 'd-none'}`}>{pickupErr.neighborhood}</div>}
                </Col>
                <Col xs="12" md="6" className="d-flex justify-content-start justify-content-sm-end overflow-x-hidden">
                  <Col xs="auto" className="me-5">
                    <Form.Label>Ciudad</Form.Label>
                    <div className="small-title">{pickup.city}</div>
                  </Col>
                  <Col xs="auto" className="me-5">
                    <Form.Label>Estado</Form.Label>
                    <div className="small-title">{pickup.state}</div>
                  </Col>
                </Col>
                <Col sm="8" className="position-relative">
                  <InputText
                    label="Referencias del lugar"
                    icon="destination"
                    maxLength="30"
                    placeholder=""
                    value={pickup.reference}
                    isRequired="true"
                    helpText=""
                    fieldName="reference"
                    validation={/^[A-Z0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.reference && <div className={`invalid-tooltip ${pickupErr.reference ? 'd-block' : 'd-none'}`}>{pickupErr.reference}</div>}
                </Col>
                <Col className="position-relative">
                  <InputText
                    label="Telefono"
                    icon="phone"
                    maxLength="15"
                    placeholder=""
                    value={pickup.phone}
                    isRequired="true"
                    helpText=""
                    fieldName="phone"
                    validation={/^[0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.phone && <div className={`invalid-tooltip ${pickupErr.phone ? 'd-block' : 'd-none'}`}>{pickupErr.phone}</div>}
                </Col>
                <Col sm="8" className="position-relative">
                  <InputText
                    label="Entre calles"
                    // icon="crop"
                    icon="minimize"
                    maxLength="30"
                    placeholder="Ej: Entre Hidalgo y Morelos"
                    value={pickup.side_streets}
                    isRequired="true"
                    helpText=""
                    fieldName="side_streets"
                    validation={/^[A-Z0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.side_streets && <div className={`invalid-tooltip ${pickupErr.side_streets ? 'd-block' : 'd-none'}`}>{pickupErr.side_streets}</div>}
                </Col>
              </Row>
              <h2 className="small-title">Información del paquete</h2>
              <Row className="g-3 mb-5">
                <Col xs="6" md="3" className="position-relative">
                  <InputText
                    label="Cantidad total"
                    icon="boxes"
                    maxLength="4"
                    placeholder=""
                    value={pickup.total_packages}
                    isRequired="true"
                    helpText=""
                    fieldName="total_packages"
                    validation={/^[0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.total_packages && (
                    <div className={`invalid-tooltip ${pickupErr.total_packages ? 'd-block' : 'd-none'}`}>{pickupErr.total_packages}</div>
                  )}
                </Col>
                <Col xs="6" md="3" className="position-relative">
                  <InputText
                    label="Peso total"
                    icon="download"
                    maxLength="4"
                    placeholder=""
                    value={pickup.total_weight}
                    isRequired="true"
                    helpText=""
                    fieldName="total_weight"
                    validation={/^[0-9 ]+$/i}
                    setField={updateField}
                    cleanError={cleanError}
                  />
                  {pickupErr.total_weight && <div className={`invalid-tooltip ${pickupErr.total_weight ? 'd-block' : 'd-none'}`}>{pickupErr.total_weight}</div>}
                </Col>
                <Col xs="12" sm="6" className="position-relative">
                  <Application theme={theme}>
                    <DatePicker
                      label="Fecha de recolección"
                      className="datepicker rainbow-p-around_medium p-0 pt-1"
                      borderRadius="semi-rounded"
                      value={selectDate.date}
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={(value) => {
                        // console.log(value);
                        if (value) {
                          setSelectDate({ ...selectDate, date: value });
                          setPickup({ ...pickup, pickup_date: moment(value).format('YYYY-MM-DD') });
                        }
                      }}
                    />
                    {pickupErr.pickup_date && <div className={`invalid-tooltip ${pickupErr.pickup_date ? 'd-block' : 'd-none'}`}>{pickupErr.pickup_date}</div>}
                  </Application>
                </Col>
                <Col xs="12" sm="6">
                  <Form.Label>Horario</Form.Label>
                  <span className="text-primary ms-1">*</span>
                  <div style={{ position: 'relative', zIndex: 1, maxWidth: '127px' }}>
                    <div className="ms-2">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={eveningSchedule}
                        onClick={() => {
                          setEveningSchedule(!eveningSchedule);
                          setPickup({ ...pickup, pickup_time: 'VESPERTINO' });
                          setPickupErr({ ...pickupErr, pickup_time: '' });
                          setMorningSchedule(false);
                        }}
                      />
                      <label className="form-label">Vespertino</label>
                    </div>
                    <div className="ms-2">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={morningSchedule}
                        onClick={() => {
                          setMorningSchedule(!morningSchedule);
                          setPickup({ ...pickup, pickup_time: 'MATUTINO' });
                          setPickupErr({ ...pickupErr, pickup_time: '' });
                          setEveningSchedule(false);
                        }}
                      />
                      <label className="form-label">Matutino</label>
                    </div>
                    {pickupErr.pickup_time && <div className={`invalid-tooltip ${pickupErr.pickup_time ? 'd-block' : 'd-none'}`}>{pickupErr.pickup_time}</div>}
                  </div>
                </Col>
              </Row>
              <div>
                Importante: Tu folio de recolección es válido únicamente por un día. Si, por alguna razón, la unidad no se presenta, puedes solicitar nuevamente
                la recolección al siguiente día hábil.
              </div>
            </Card.Body>
          </Card>
          {/* End */}
        </Col>
      </Form>
    </>
  );
};

export default NewPickup;
