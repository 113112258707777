import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
// external libraries
import moment from 'moment';
// External libraries
const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});
const ShipmentCard = ({ shipping }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-9 position-relative">
        <Col xs="auto" className="positio-relative">
          <NavLink to={`/envios/historial/detalle/${shipping.shipping_label_id}`}>
            <img src={shipping.service_img} alt="servicio" className="card-img card-img-horizontal sw-11 h-100" />
          </NavLink>
        </Col>
        <Col className="py-4 py-lg-0 ps-3 pe-4 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" sm="10" lg="7" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center order-1">
              <div className="text-muted text-small">Servicio / ID Envío</div>
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
                <NavLink to={`/envios/historial/detalle/${shipping.shipping_label_id}`}>{shipping.shipping_code?.replace(/_+/g, ' ')}</NavLink>
              </OverlayTrigger>
              <div className="text-medium text-muted text-truncate">{shipping.shipping_label_id}</div>
            </Col>
            <Col xs="6" lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-center order-2 order-sm-3">
              <div className="text-muted text-small">Fecha</div>
              <div className="lh-1 text-alternate">{moment(shipping.creation_date).format('DD-MM-YY')}</div>
            </Col>
            <Col xs="6" sm="2" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-end order-3 order-sm-2 order-lg-3">
              <div className="text-muted text-small">Costo</div>
              <div className={`${shipping.refunded ? 'text-success' : 'text-primary'}`}>
                <span className="text-small text-muted">$</span>
                {fmDecimal.from(shipping.shipping_rates.total)}
                <div className={`text-end text-small text-success ${shipping.refunded ? 'd-block' : 'd-none'}`}>devuelta</div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default ShipmentCard;
