import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
// reducer
import { setAddressDetail } from '../../../../slices/addresses/addressDataSlice';

const CardAddress = ({ address, setIsModalOpen }) => {
  const dispatch = useDispatch();

  const openModalAddress = () => {
    dispatch(setAddressDetail(address));
    setIsModalOpen(true);
    // console.log('*****:', address);
  };
  return (
    <Card className="mb-2">
      <Card.Body className="py-3 py-lg-0 sh-auto sh-lg-9">
        <Row className="g-0 h-100 align-content-center">
          <Col xs="11" lg="3" className="mb-2 mb-lg-0 pe-lg-4 d-flex flex-column justify-content-center order-1 order-lg-1 h-lg-100 position-relative">
            <div className="text-muted text-small d-lg-none">Nombre</div>
            <OverlayTrigger delay={{ show: 500, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Haz clic para ver detalle de dirección</Tooltip>}>
              <button
                type="button"
                className="btn-open-addresses-modal text-primary text-start text-nowrap"
                // Width with medias  maxWidth: '200px'
                style={{ backgroundColor: 'transparent', borderStyle: 'none', overflowX: 'scroll', maxWidth: '100%' }}
                onClick={() => openModalAddress()}
              >
                {address.name.toUpperCase()}
              </button>
            </OverlayTrigger>
          </Col>
          <Col xs="12" lg="5" className="mb-3 mb-lg-0 pe-lg-4 d-flex flex-column justify-content-center order-2 order-lg-3">
            <div className="text-muted text-small d-lg-none">Calle</div>
            <div
              className="text-alternate lh-1.5 text-nowrap"
              style={{
                overflowX: 'scroll',
                maxWidth: '100%',
              }}
            >
              {address.street.toUpperCase()}
            </div>
          </Col>
          <Col
            xs="6"
            sm="3"
            lg="2"
            className="d-lg-none d-flex flex-column justify-content-center align-items-start align-items-lg-center mb-2 mb-lg-0 order-3 order-lg-4"
          >
            <div className="text-muted text-small d-lg-none">Número</div>
            <div className="text-alternate pe-4"> {address.street_number}</div>
          </Col>
          <Col xs="9" lg="3" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-4 order-lg-4">
            <div className="text-muted text-small d-lg-none">Colonia</div>
            <div className="text-alternate pe-2 ">{address.neighborhood.toUpperCase()}</div>
          </Col>
          <Col xs="12" lg="1" className="d-flex flex-column justify-content-center align-items-end mb-2 mb-lg-0 order-last order-lg-5">
            <div className="text-muted text-small d-lg-none mb-1">C.P</div>
            <div className="text-alternate">{address.zip_code}</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default CardAddress;
