import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
// api
import { getBillingDocs } from '../../../../../api/Api-admin';
// components
import BillingRow from '../components/BillingRow';
import BillingCard from '../components/BillingCard';
import Loading from '../../../../../../components/loading/LoadingRed';

const LastInvoices = ({ crmId }) => {
  const [invoices, setInvoices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const handlerGetInvoices = () => {
      getBillingDocs('invoices', crmId, 1, 5)
        .then((response) => {
          // console.log('Get invoices response', response);
          if (response.data !== undefined) {
            setIsLoading(false);
            setInvoices(response.data);
            setMessage('No hay facturas registradas.');
          }
        })
        .catch(() => {
          setIsLoading(false);
          setInvoices([]);
          setMessage('Error: No se pudieron obtener las últimas facturas. Por favor, verifica tu conexión a Internet e inténtalo nuevamente.');

          // console.log('Err catch get invoices', err);
        });
    };
    handlerGetInvoices();
  }, []);

  // console.log(invoices)
  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {invoices?.length === 0 ? (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>{message}</h3>
        </Row>
      ) : (
        <>
          {/* List Header Start */}
          {invoices?.length > 0 && <BillingRow />}
          {/* List Header End */}
          {/* List Items Start */}
          {invoices?.map((invoice, index) => (
            <BillingCard data={invoice} key={index} crmId={crmId} doc="Factura" />
          ))}
          {/* List Items End */}
        </>
      )}
    </>
  );
};

export default LastInvoices;
