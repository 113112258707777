import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// components
import HtmlHead from '../../../components/html-head/HtmlHead';
import ButtonRequest from '../../../components/buttons/ButtonRequest';
import { ToastSuccess } from '../../../components/alerts/toast';

import LoadingCar from '../../../components/loading/LoadingCar';
// api
import { getAvailableServices, deactivateService, activateService } from '../../../api/Api-user';

const AvailableServices = () => {
  const title = 'Servicios Disponibles';
  const description = 'Servicios Disponibles Plataforma Autopaquete';
  // - Local States
  const [services, setServices] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedService, setSelectedService] = useState({});
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const closeModal = () => {
    setIsModalActive(false);
  };
  const handlerGetServices = async () => {
    try {
      const response = await getAvailableServices();
      // console.log('get available services response:', response);
      if (response.success) {
        setServices(response.list_available_services);
      } else if (response.error) {
        setServices(services || []);
        handleAPIError(response);
      }
    } catch (error) {
      setServices(services || []);
      handleCatchError(error);
    }
  };

  const toggleService = async () => {
    try {
      setIsAddChargerLoader(true);
      const serviceAction = selectedService.restricted ? activateService : deactivateService;
      const response = await serviceAction(selectedService.public_name);
      // console.log('deactivate response:', response);
      if (response.success) {
        setIsModalActive(false);
        setTrigger((prev) => !prev);
        ToastSuccess(selectedService.restricted ? 'El servicio se activó correctamente.' : 'El servicio se desactivó correctamente.');
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsAddChargerLoader(false);
    }
  };

  useEffect(() => {
    handlerGetServices();
  }, [trigger]);
  // useEffect(() => {
  //   console.log('servicio seleccionado:', selectedService);
  // }, [selectedService]);
  return (
    <>
      {services === null && <LoadingCar />}
      <Modal show={isModalActive} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">{selectedService.public_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-infow">
          <div className="text-semi-large">{`¿Estas segur@ de que deseas ${selectedService.restricted ? 'activar' : 'desactivar'} este servicio?`}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap justify-content-end bg-warningd">
            <ButtonRequest
              title="Si, continuar"
              titleLoading="Cargando"
              handlerFn={toggleService}
              isButtonDisabled={isAddChargerLoader}
              isLoading={isAddChargerLoader}
            />
            <Button variant="outline-primary" style={{ height: '36px' }} className="w-auto ms-2 mb-2" onClick={closeModal}>
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <Row className="g-0 mb-3">
          {/* Title Start col-auto */}
          <Col xs="auto" className="col-auto mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Inicio</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}
        </Row>
        <Row>
          {services?.map((service, index) => (
            <OverlayTrigger
              key={index}
              delay={{ show: 1000, hide: 0 }}
              placement="top"
              overlay={<Tooltip id="tooltip-top">Haz clic para activar o desactivar este servicio</Tooltip>}
            >
              <Card
                className="mb-2 me-4 ps-0 w-40 cursor-pointer"
                style={{ minWidth: '400px' }}
                onClick={() => {
                  setSelectedService(service);
                  setIsModalActive(true);
                }}
              >
                <Row className="g-0 h-100 sh-lg-9 position-relative">
                  <Col xs="auto" className="positio-relative">
                    <img src={service.service_img} alt="servicio" className="card-img card-img-horizontal sw-11 h-100" />
                  </Col>
                  <Col className="py-4 py-lg-0 ps-3 pe-4 h-100">
                    <Row className="g-0 h-100 align-content-center">
                      <Col xs="10" className="d-flex flex-column mb-lg-0 mb-3 pe-3  h-lg-100 justify-content-center">
                        {/* <div className="text-muted text-small">Servicio / ID Envío</div> */}
                        <div className="text-primary">{service.public_name}</div>
                      </Col>
                      <Col xs="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-end">
                        <div className="text-muted text-small">Activo</div>
                        <span>
                          {service.restricted ? <CsLineIcons icon="close" size="15" /> : <CsLineIcons icon="check" className="text-success" size="15" />}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </OverlayTrigger>
          ))}
        </Row>
      </div>
    </>
  );
};
export default AvailableServices;
