import React, { useState } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { useSelector } from 'react-redux';
// components
import EditUserModal from './EditUserModal';

const EditUser = ({ user, setUpdatePage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const data = {
    crmId: user.crm_user_id,
    isAccountActive: user.is_active,
    isAutoBalance: user.automatic_balance,
    isAutoVouchers: user.automatic_invoice, // com fiscales
    isMultiPieces: user.is_multipiece_available,
    customerType: user.customer_type,
    adviser: user.seller,
    rate: user.rates_list === null ? '-- x --' : user.rates_list, // tarifario
    labelType: user.label_type === null ? '-- x --' : user.label_type, // etiqueta
    taxSystem: user.tax_system, // regimen
    zip: user.zip_code,
  };

  return (
    <>
      {/* Edit user Modal Start */}
      {isModalOpen && <EditUserModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={user} setUpdatePage={setUpdatePage} />}
      {/*  Edit user Modal End */}
      <Card className="mb-5 mt-0 mt-lg-0 pt-lg-2">
        <Card.Body className="h-100">
          <Row className="g-0 bg-infok">
            <Col xs="12" md="6" xl="12" className="bg-successx d-flex flex-column justify-content-start">
              <div className="text-alternate mb-2">
                {data.isAccountActive ? (
                  <CsLineIcons icon="activity" size="19" className="text-muted me-2" />
                ) : (
                  <CsLineIcons icon="close-circle" size="19" className="text-muted me-2" />
                )}
                CUENTA
                <span className="ps-2 text-primary">{data.isAccountActive ? 'ACTIVA' : 'INACTIVA'}</span>
              </div>
              <div className="text-alternate mb-2">
                <CsLineIcons icon="dollar" size="19" className="text-muted me-2" />
                SALDO AUTOMÁTICO
                <span className="ps-2 text-primary">{data.isAutoBalance ? 'SI' : 'NO'}</span>
              </div>
              <div className="text-alternate mb-2">
                <CsLineIcons icon="invoice" size="19" className="text-muted me-2" />
                COMPROBANTE AUTOMÁTICO
                <span className="ps-2 text-primary">{data.isAutoVouchers ? 'SI' : 'NO'}</span>
              </div>
              <div className="text-alternate mb-2">
                <CsLineIcons icon="duplicate" size="19" className="text-muted me-2" />
                MULTIPIEZAS
                <span className="ps-2 text-primary">{data.isMultiPieces ? 'SI' : 'NO'}</span>
              </div>
            </Col>
            <Col xs="12" md="6" xl="12" className="bg-warnings d-flex flex-column justify-content-start">
              <div className="text-alternate mb-2">
                <CsLineIcons icon="shield" size="19" className="text-muted me-2" />
                CLIENTE TIPO
                <span className="ps-2 text-primary">{data.customerType}</span>
              </div>
              <div className="text-alternate mb-2">
                <CsLineIcons icon="support" size="19" className="text-muted me-2" />
                VENDEDOR
                <span className="ps-2 text-primary">{data.adviser}</span>
              </div>
              <div className="text-alternate mb-2">
                {data.rates_list !== null ? (
                  <CsLineIcons icon="star" size="19" className="text-muted me-2" />
                ) : (
                  <CsLineIcons icon="close-circle" size="19" className="text-muted me-2" />
                )}
                TARIFARIO
                <span className="ps-2 text-primary">{data.rate}</span>
              </div>
              <div className="text-alternate mb-2">
                {data.labelType !== null ? (
                  <CsLineIcons icon="print" size="19" className="text-muted me-2" />
                ) : (
                  <CsLineIcons icon="close-circle" size="19" className="text-muted me-2" />
                )}
                ETIQUETA
                <span className="ps-2 text-primary">{data.labelType}</span>
              </div>
              <div className="text-alternate mb-2">
                <CsLineIcons icon="suitcase" size="19" className="text-muted me-2" />
                REGIMEN
                <span className="ps-2 text-primary">{data.taxSystem}</span>
              </div>
              <div className="text-alternate mb-2">
                <CsLineIcons icon="pin" size="19" className="text-muted me-2" />
                CP:
                <span className="ps-2 text-primary">{data.zip}</span>
              </div>
            </Col>
            {currentUser.access === 'Control Total' && (
              <Col xs="12" className="bg-mutedx d-flex justify-content-end align-items-end mt-3">
                <Button
                  variant="primary"
                  className="btn-icon btn-icon-start w-auto mt-3 mt-lg-1"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <CsLineIcons icon="edit" size="18" />
                  <span> Editar </span>
                </Button>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditUser;
