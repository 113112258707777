import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row } from 'react-bootstrap';
// External libraries
// api
import { getStatements } from '../../../../../api/Api-admin';
// components
import Loading from '../../../../../../components/loading/LoadingRed';
import StatementCard from './components/StatementCard';
import StatementRow from './components/StatementRow';

const LastStatements = ({ crmId, userName, updatePage }) => {
  const [statements, setStatements] = useState(null);
  const [totalStatements, setTotalStatements] = useState(0);
  const [error, setError] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const handlerGetStatements = async () => {
    try {
      setIsLoading(true);
      const response = await getStatements(crmId, 1, 5);
      // console.log('response statemnets:', response);
      if (response.statement !== undefined) {
        setStatements(response.statement);
        setTotalStatements(response.total_items);
      } else if (response.error) {
        const errorMessage = response.error.error.admin[0].trim();
        setError(errorMessage);
      }
    } catch {
      setIsLoading(false);
      setError('Error: No se pudieron obtener los últimos movimientos. Por favor, verifica tu conexión a Internet e inténtalo nuevamente.');
      // console.log('error catch');
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handlerGetStatements();
  }, [updatePage]);
  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {error && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>{error}</h3>
        </Row>
      )}
      {statements?.length === 0 && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>No hay movimientos registrados</h3>
        </Row>
      )}
      {totalStatements > 5 && (
        <NavLink to={`/users-all/statements/${crmId}/${userName}`} className="mb-3 cursor-pointer text-decoration-underline text-end">
          Ver todos
        </NavLink>
      )}

      {statements?.length > 0 && <StatementRow />}

      {statements?.map((statement, index) => (
        <StatementCard data={statement} key={index} />
      ))}
    </>
  );
};

export default LastStatements;
