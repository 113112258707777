import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// External libraries
import moment from 'moment';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const StatementCard = ({ data }) => {
  return (
    <Card className="mb-2 px-0">
      <Card.Body className="py-3 px-4 py-lg-0 sh-auto sh-lg-8 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
        <Row className="g-0 h-100 align-content-center cursor-default">
          <Col xs="12" sm="9" lg="3" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-1 order-lg-1 h-lg-100 position-relative">
            <div className="text-muted text-small d-lg-none">Concepto</div>
            <div className="p-0 pe-2 text-primary h-100 d-flex align-items-center">{data.statement_text.toUpperCase()}</div>
          </Col>
          <Col xs="12" sm="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-3 order-lg-2">
            <div className="text-muted text-small d-lg-none">Fecha Movimiento</div>
            {/* ${statement.creation_date.slice(11, 19)} */}
            <div className="text-alternate">{`${moment(data.created_date).format('DD-MM-YYYY')}`}</div>
          </Col>
          <Col xs="12" sm="6" lg="2" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-4 order-lg-3">
            <div className="text-muted text-small d-lg-none">Referencia</div>
            <div className="text-alternate">{data.reference}</div>
          </Col>
          <Col xs="12" sm="6" lg="3" className="d-flex flex-column justify-content-center mb-2 mb-lg-0 order-4 order-lg-3 ps-4">
            <div className="text-muted text-small d-lg-none">Referencia Adicional</div>
            <div className="text-alternate">{data.statement_text === 'Retorno Guia' && <span>{data.reference_trace}</span>}</div>
          </Col>
          <Col
            xs="12"
            sm="12"
            lg="1"
            className="d-flex flex-column justify-content-center align-items-end align-items-lg-center mb-2 mb-lg-0 order-last order-lg-5"
          >
            <div className="text-muted text-small d-lg-none">Monto</div>
            <span
              className={`text-alternative ${
                data.statement_code === 'CDS' || data.statement_code === 'RGNU' || data.statement_code === 'DCI' || data.statement_code === 'RAL'
                  ? 'text-success'
                  : 'text-primary'
              }`}
            >
              <span className="text-small text-muted">$</span>
              {fmDecimal.from(data.amount)}
              {/* {data.amount} */}
            </span>
          </Col>
          <Col xs="12" sm="3" lg="1" className="d-flex flex-column justify-content-center align-items-lg-end mb-2 mb-lg-0 order-2 text-end order-lg-last">
            <div className="text-muted text-small d-lg-none">SALDO</div>
            <span className="text-alternative text-alternate">
              <span className="text-small text-muted ">$</span>
              {/* {fmDecimal.from(balance, { symbol: '$' })} */}
              {fmDecimal.from(data.current_balance)}
            </span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default StatementCard;
