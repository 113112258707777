import React, { useEffect, useState } from 'react';
import { Modal, Form, Dropdown } from 'react-bootstrap';
// components
import ButtonRequest from '../../../../components/buttons/ButtonRequest';
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../../../../components/alerts/toast';

// api
import { addIncreaseBalance, addAdditionalCharge, getChargeConcepts } from '../../../api/Api-admin';

const BalanceModal = ({ isModalOpen, setIsModalOpen, title, type, crmId, setUpdatePage }) => {
  const [chargeConceptList, setChargeConceptList] = useState([]);
  const [nameConcept, setNameConcept] = useState('Concepto');
  const [codeConcept, setCodeConcept] = useState('');
  const [reference, setReference] = useState('');
  const [referenceTrace, setReferenceTrace] = useState('');
  const [labelId, setLabelId] = useState('');
  const [chargeAmount, setChargeAmount] = useState('');
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const optionalDecimalRegex = /^\d*\.?\d*$/;
  const updateField = (value) => {
    if (value === '' || optionalDecimalRegex.test(value)) {
      setChargeAmount(value);
    }
  };
  const closeModal = () => {
    setChargeAmount('');
    setNameConcept('Concepto');
    setCodeConcept('');
    setLabelId('');
    setIsModalOpen(false);
  };
  const closeModalAndRenderData = () => {
    closeModal();
    setUpdatePage(true);
  };

  const handlerIncreaseBalance = async () => {
    try {
      if (chargeAmount && codeConcept && reference) {
        const response = await addIncreaseBalance(chargeAmount, codeConcept, crmId, reference);
        if (response.client_id !== undefined) {
          ToastSuccess('Se agrego saldo correctamente ⭐');
          closeModalAndRenderData();
        } else if (response.error) {
          const firstKey = Object.keys(response.error)[0];
          ToastErrorApi(response.error[firstKey]);
        }
      } else {
        ToastErrorCath('Error: Todos los campos son requeridos');
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsAddChargerLoader(false); // active loader in the button
    }
  };
  const handlerAddCharges = async () => {
    try {
      if (chargeAmount && codeConcept && labelId) {
        // Validar si nameConcept es 'Retorno Guia' y si referenceTrace está vacío
        if (nameConcept === 'Retorno Guia' && !referenceTrace) {
          ToastErrorCath('El campo referencia es obligatorio para el concepto "Retorno Guia".');
          return; // Detenemos la ejecución si la validación falla
        }
        const response = await addAdditionalCharge(crmId, labelId, chargeAmount, codeConcept, referenceTrace);
        if (response.client_id !== undefined) {
          ToastSuccess('Se aplico cargo correctamente ⭐');
          closeModalAndRenderData();
        } else if (response.error) {
          const firstKey = Object.keys(response.error)[0];
          ToastErrorApi(response.error[firstKey]);
        }
      } else {
        ToastErrorCath('Error: Todos los campos son requeridos');
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsAddChargerLoader(false); // active loader in the button
    }
  };
  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsAddChargerLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  const handleButtonClick = () => {
    handleButtonAction();
    if (type === 'increase') {
      handlerIncreaseBalance();
    } else {
      handlerAddCharges();
    }
  };
  useEffect(() => {
    // REDUCES_BALANCE   INCREASES_BALANCE
    if (type === 'increase') {
      getChargeConcepts('INCREASES_BALANCE').then((response) => {
        // console.log('concepts res:', response);
        setChargeConceptList(response.list);
      });
    } else if (type === 'reduce') {
      getChargeConcepts('REDUCES_BALANCE').then((response) => {
        // console.log('concepts res:', response);
        setChargeConceptList(response.list);
      });
    }
  }, [type]);
  // console.log('concept:', nameConcept);
  // console.log('concept code:', codeConcept);  en la guia numero: ${labelId}
  // console.log('cargo adicional data:', crmId, labelId);

  return (
    <Modal show={isModalOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <>
          {type === 'increase' ? (
            <Form.Control
              type="text"
              style={{ fontFamily: 'Montserrat' }}
              className="nav-shadow me-2 w-50 my-3 my-sm-0"
              placeholder="Referencia"
              value={reference}
              onChange={(e) => {
                setReference(e.target.value);
              }}
            />
          ) : (
            <>
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat' }}
                className="nav-shadow me-2 w-50 my-3 my-sm-0"
                placeholder="ID Envío"
                value={labelId}
                onChange={(e) => {
                  setLabelId(e.target.value);
                }}
              />
              {nameConcept === 'Retorno Guia' && (
                <Form.Control
                  type="text"
                  style={{ fontFamily: 'Montserrat' }}
                  className="nav-shadow me-2 w-50 my-3 my-sm-0"
                  placeholder="ID Envío Retorno"
                  value={referenceTrace}
                  onChange={(e) => {
                    setReferenceTrace(e.target.value);
                  }}
                />
              )}
            </>
          )}
        </>
      </Modal.Body>
      <Modal.Footer className="px-3">
        <div className="d-flex flex-wrap justify-content-end bg-warningd">
          <Dropdown className="d-inline-block mb-2">
            <Dropdown.Toggle
              variant="foreground-alternate"
              className="shadow d-flex justify-content-between align-items-center ps-2 ps-sm-3 pe-3"
              style={{ width: '290px' }}
            >
              {nameConcept}
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow " style={{ maxHeight: '250px', overflowY: 'scroll', fontFamily: 'Montserrat' }}>
              {chargeConceptList.length > 0 &&
                chargeConceptList.map((element, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setNameConcept(element.name_concept);
                      setCodeConcept(element.code_concept);
                    }}
                  >
                    {element.name_concept}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
          <Form.Control
            type="text"
            style={{ fontFamily: 'Montserrat', width: '80px' }}
            className="nav-shadow ms-2 mb-2"
            placeholder="$0"
            value={chargeAmount}
            onChange={(e) => {
              updateField(e.target.value);
            }}
          />
          <ButtonRequest
            title="Aplicar"
            titleLoading="Cargando"
            handlerFn={handleButtonClick}
            isButtonDisabled={isButtonDisabled}
            isLoading={isAddChargerLoader}
          />

          {/* <Button
            variant="primary"
            disabled={isButtonDisabled}
            style={{ height: '36px' }}
            className="w-auto ms-2 mb-2 d-flex justify-content-between align-items-center"
            // onClick={type === 'increase' ? handlerIncreaseBalance : handlerAddCharges}
            onClick={handleButtonClick}
          >
            {isAddChargerLoader && (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                style={{
                  width: '13px',
                  height: '13px',
                  borderWidth: '1px',
                  color: '#FFFF',
                }}
              />
            )}
            <span>Aplicar</span>
          </Button> */}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default BalanceModal;
