import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
// external libraries
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// utils
import { initialsUpperCase } from 'utils/textFormat';
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// api
import { getCounters } from '../../api/Api-user';
// components
import ShippingRefundModal from './components/ShippingRefundModal';
import CounterCard from './components/CounterCard';
import Loading from '../../components/loading/LoadingRed';
import ShipmentCard from './components/ShipmentCard';
import PickupCard from './components/PickupCard';
import DashboardCard from '../../components/cards/DashboardCard';
import { ToastErrorCath } from '../../components/alerts/toast';

const Dashboard = () => {
  const title = 'Inicio';
  const description = 'Inicio Plataforma Autopaquete';
  // const dispatch = useDispatch();
  const history = useHistory();
  // - Local States
  const [counters, setCounters] = useState({
    balance: 0,
    shippings: 0,
    pickups: 0,
  });
  const [lastShippings, setLastShippings] = useState([]);
  const [lastPickups, setLastPickups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // - Global State from Store.js form Values
  const { isLogin, currentUser } = useSelector((state) => state.auth);
  //  const { dashboardData } = useSelector((state) => state.init_data);
  // console.log('current user:', currentUser);
  // console.log('token desde el storage:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key_production);
  if (!isLogin) {
    history.push('/login');
    // history.goBack();
  }

  const handlerGetCounters = async () => {
    try {
      setIsLoading(true);
      const response = await getCounters();
      // console.log('response counters:', response);
      if (response.current_balance !== undefined) {
        setCounters({
          balance: response.current_balance,
          shippings: response.shippings_counter_last_month,
          pickups: response.pickups_counter_last_month,
        });
        setLastShippings(response.shippings);
        setLastPickups(response.pickups);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
      // ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handlerGetCounters();
  }, []);
  // console.log('contadores:', counters);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  return (
    <>
      <ShippingRefundModal setIsRefundModalOpen={setIsRefundModalOpen} isRefundModalOpen={isRefundModalOpen} />
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
          <span className="align-middle text-small ms-1">&nbsp;</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4 text-center text-sm-start lh-base" id="title">
          {`¡Bienvenid@ ${initialsUpperCase(currentUser.name)}! ✨ `}
        </h1>
      </div>
      {/* Title End */}
      {/* Counter Cards Start */}
      <Row className="mb-5 g-2">
        <CounterCard icon="dollar" title="saldo actual" counter={counters.balance} />
        <CounterCard icon="credit-card" title="clabe stp" counter={currentUser.clabe} />
        <CounterCard icon="shipping" title="Envíos del mes" counter={counters.shippings} />
        <CounterCard icon="destination" title="recolecciones del mes" counter={counters.pickups} />
      </Row>
      {/* Counter Cards End */}
      <Row>
        {/* Last Shippings Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Últimos Envíos Realizados</h2>
          <div style={{ minHeight: '150px' }}>
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                <Loading />
              </div>
            )}

            {!isLoading && lastShippings.length === 0 && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                <p>No hay envíos recientes</p>
                {/* También puedes usar una imagen si prefieres */}
                {/* <img src="ruta_de_la_imagen" alt="No hay envíos" /> */}
              </div>
            )}

            {!isLoading && lastShippings.length > 0 && lastShippings.map((shipping) => <ShipmentCard key={shipping.id} shipping={shipping} />)}
          </div>
        </Col>
        {/* Last Shippings End */}
        {/* Last Pickups Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Últimas Recolecciones Realizadas</h2>
          <div style={{ minHeight: '150px' }}>
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                <Loading />
              </div>
            )}

            {!isLoading && lastPickups.length === 0 && (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                <p>No hay recolecciones recientes</p>
                {/* También puedes usar una imagen si prefieres */}
                {/* <img src="ruta_de_la_imagen" alt="No hay envíos" /> */}
              </div>
            )}

            {!isLoading && lastPickups.length > 0 && lastPickups.map((pickup) => <PickupCard key={pickup.id} pickup={pickup} />)}
          </div>
        </Col>
        {/* Last Pickups End */}
      </Row>
      {/* Cards Start */}
      <Row>
        <DashboardCard
          img="/img/autopaquete/enviar/manual.webp"
          title="¿Nuevo por aquí?"
          subTitle="Introducción"
          description="Conoce las herramientas y uso de nuestra plataforma a través de este breve video."
          url="https://youtu.be/YSbmX0oNCUU"
          btnTitle="Ver manual"
        />
        <DashboardCard
          img="/img/autopaquete/enviar/recarga.webp"
          title="¿Tienes dudas?"
          subTitle="Manual de usuario"
          description="Explora nuestra guía de preguntas frecuentes diseñada para resolver todas tus inquietudes."
          url="https://autopaquete-log.glide.page/dl/d0a5f4"
          btnTitle="Click aquí"
        />
        <DashboardCard
          img="/img/autopaquete/enviar/soporte.webp"
          title="¿Tu paquete tiene incidencia?"
          subTitle="Realizar reporte"
          description="Por favor compártenos la información requerida sobre tu paquete, nosotros haremos el reporte a la paquetería."
          // url="/reportes/crear-reporte"
          url="https://app.autopaquete.com.mx/reportes/crear-reporte"
          btnTitle="Nuevo Reporte"
        />
        {/* <DashboardCard
          img="/img/autopaquete/enviar/reembolso.webp"
          title="¿Guía sin usar?"
          subTitle="Reembolso de guías"
          description="Nuestro proceso de reembolso de guías está cuidadosamente diseñado para ofrecerte una experiencia sin complicaciones."
          url="https://zfrmz.com/Q8Rd1j4XfS8vnVpdCnBu"
          btnTitle="Click aquí"
        /> */}
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/reembolso.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Guía sin usar?</div>
                <div className="mb-3 cta-3 text-primary">Reembolso de guías</div>
                <div className="text-alternate mb-4">
                  Nuestro proceso de reembolso de guías está cuidadosamente diseñado para ofrecerte una experiencia sin complicaciones.
                </div>
              </div>
              <Button
                variant="outline-primary"
                className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1"
                onClick={() => {
                  setIsRefundModalOpen(true);
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Click aquí</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Cards End */}
    </>
  );
};

export default Dashboard;
